



























































import Vue from 'vue'
import { api } from '../api'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import i18n from './../i18n'

import { EventBus } from '@/event-bus'

import dayjs from 'dayjs'
require('dayjs/locale/it')
dayjs.locale(navigator.language || 'it')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

export default Vue.extend({
	name: 'Home',
	props: ['type'],
	data() {
		return {
			dayjs,
			recruiter: {
				firstName: 'Nome recruiter',
				lastName: 'Cognome recruiter',
				company: 'Azienda recruiter',
				avatar: 'https://placekitten.com/80'
			},
			eventInformation: null,
			slotType: '',
			step: 'showInfo',
			rejectMessage: ''
		}
	},
	mounted(): void {
		i18n.locale = this.getUrlParameter('lang') || 'it'
		if (this.type === 'confirm') this.confirmEvent()
		else {
			api.get(`/candidate/event?req_id=${this.getUrlParameter('req_id')}`)
				.then(({ data }) => {
					this.recruiter = {
						firstName: data.recruiterName,
						lastName: data.recruiterLastName,
						company: data.recruiterCompany,
						avatar: data.recruiterAvatar
					}

					this.eventInformation = data.events[0]

					// colloquio_presenza colloquio_telefonico live_call
					this.slotType = data.invitationAttributes?.find((f: { name: string; value: string }) => f.name === 'booking_type')?.value

					this.step = 'rejectSlot'
				})
				.catch((error) => {
					if (error.response?.data?.messageCode === 'BKG-006') {
						// slot removed by recruiter
						this.step = 'slotRemoved'
					} else if (error.response?.data?.messageCode === 'BKG-015') {
						// slot expired
						this.step = 'slotExpired'
					}
				})
				.finally(() => {
					EventBus.$emit('hideSpinner')
				})
		}
	},
	methods: {
		/**
		 * extract a parameter value from the page url using a given parameter `name`
		 * @param name the parameter name to search for
		 * @returns the value of the parameter
		 */
		getUrlParameter(name: string): string {
			// name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
		},

		confirmEvent() {
			EventBus.$emit('showSpinner')
			api.get(`/candidate/events/confirmevent?req_id=${this.getUrlParameter('req_id')}`)
				.then(({ data }) => {
					this.eventInformation = data.event
					this.recruiter = {
						firstName: data.recruiterName,
						lastName: data.recruiterLastName,
						company: data.recruiterCompany,
						avatar: data.recruiterAvatar
					}

					// colloquio_presenza colloquio_telefonico live_call
					this.slotType = data.event?.candidates[0]?.assignAttributes?.find((f: { name: string; value: string }) => f.name === 'booking_type')?.value
				})
				.catch((error) => {
					if (error.response?.data?.messageCode === 'BKG-006') {
						// slot removed by recruiter
						this.step = 'slotRemoved'
					} else if (error.response?.data?.messageCode === 'BKG-015') {
						// slot expired
						this.step = 'slotExpired'
					}
				})
				.finally(() => {
					EventBus.$emit('hideSpinner')
				})
		},

		rejectEvent() {
			EventBus.$emit('showSpinner')
			api.post('/candidate/events/rejectevent', {
				invitationReqId: this.getUrlParameter('req_id'),
				rejectMessage: this.rejectMessage
			})
				.then(({ data }) => {
					this.eventInformation = data.event
					this.recruiter = {
						firstName: data.recruiterName,
						lastName: data.recruiterLastName,
						company: data.recruiterCompany,
						avatar: data.recruiterAvatar
					}

					// colloquio_presenza colloquio_telefonico live_call
					this.slotType = data.event?.candidates[0]?.assignAttributes?.find((f: { name: string; value: string }) => f.name === 'booking_type')?.value

					this.step = 'showInfo'
				})
				.catch((error) => {
					if (error.response?.data?.messageCode === 'BKG-006') {
						// slot removed by recruiter
						this.step = 'slotRemoved'
					} else if (error.response?.data?.messageCode === 'BKG-015') {
						// slot expired
						this.step = 'slotExpired'
					}
				})
				.finally(() => {
					EventBus.$emit('hideSpinner')
				})
		}
	}
})
