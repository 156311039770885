<template>
	<div id="app">
		<Spinner />
		<app-header v-if="!meetJoined" />
		<router-view />
	</div>
</template>

<script>
import { EventBus } from '@/event-bus'
import AppHeader from '@/components/AppHeader.vue'
import Spinner from '@/components/Spinner.vue'
import i18n from './i18n'

export default {
	data() {
		return {
			meetJoined: false
		}
	},
	components: {
		AppHeader,
		Spinner
	},
	beforeMount() {
		const regexPattern = /\/meet\/([a-f0-9-]+)&lang=/
		const currentURL = window.location.href
		console.log('redirect', regexPattern.test(currentURL))
		if (regexPattern.test(currentURL)) {
			const newURL = currentURL.replace(/&lang=/, '?lang=')
			window.location.href = newURL
		}
	},
	mounted() {
		i18n.locale = this.getUrlParameter('lang') || 'it'

		EventBus.$on('meetJoined', () => {
			this.meetJoined = true
		})
	},
	methods: {
		/**
		 * extract a parameter value from the page url using a given parameter `name`
		 * @param name the parameter name to search for
		 * @returns the value of the parameter
		 */
		getUrlParameter(name) {
			// name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
		}
	}
}
</script>

<style lang="scss">
@import './assets/styles/main.scss';
</style>
