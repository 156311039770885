<template>
	<div class="container">
		<div class="video-container">
			<video v-show="!mediapipe" class="input_video" controls="false" autoplay playsinline height="400" width="600" :muted="false" ref="video" @click.prevent.stop=""></video>
			<canvas v-show="mediapipe" class="output_canvas" height="400" width="600"></canvas>

			<span v-if="!enableVideo" class="video-disabled-placeholder"> {{ $t('webcam_disabled') }} </span>

			<div class="video-container-actions">
				<button class="media-action-button" @click="toggleVideo()" :class="{ 'media-action-button-disabled': !enableVideo }">
					<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
						<path
							:d="
								enableVideo
									? 'M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z'
									: 'M3.27,2L2,3.27L4.73,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16C16.2,18 16.39,17.92 16.54,17.82L19.73,21L21,19.73M21,6.5L17,10.5V7A1,1 0 0,0 16,6H9.82L21,17.18V6.5Z'
							"
						/>
					</svg>
				</button>

				<button class="media-action-button" @click="toggleAudio()" :class="{ 'media-action-button-disabled': !enableAudio }">
					<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
						<path
							:d="
								enableAudio
									? 'M12,2A3,3 0 0,1 15,5V11A3,3 0 0,1 12,14A3,3 0 0,1 9,11V5A3,3 0 0,1 12,2M19,11C19,14.53 16.39,17.44 13,17.93V21H11V17.93C7.61,17.44 5,14.53 5,11H7A5,5 0 0,0 12,16A5,5 0 0,0 17,11H19Z'
									: 'M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z'
							"
						/>
					</svg>
				</button>

				<button v-if="false" class="media-action-button" @click="toggleMediaPipe()" :class="{ 'media-action-button-disabled': !enableVideo }">
					<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
						<path
							fill="currentColor"
							d="M10.6 9.6L9 15L7.4 9.6L2 8L7.4 6.4L9 1L10.6 6.4L16 8L10.6 9.6M17 14.2L21 12L18.8 16L21 20L17 17.8L13 20L15.2 16L13 12L17 14.2M10 16L8.3 19L10 22L7 20.3L4 22L5.7 19L4 16L7 17.7L10 16"
						/>
					</svg>
				</button>
			</div>
		</div>

		<form class="meet-information" @submit.prevent.stop="onJoin">
			<div class="meet-title">{{ meetName }}</div>
			<div class="meet-partecipants">
				<div v-if="partecipants.length" class="meet-partecipants-list-item">
					<div class="avatar" v-for="(partecipant, i) in partecipants" :key="i" :style="partecipant.avatarColor ? 'background-color:' + partecipant.avatarColor : 'red'">
						{{ getName(partecipant) }}
					</div>
				</div>

				<div>{{ getPartecipantDescription }}</div>
			</div>
			<div class="form">
				<div class="form__group">
					<input type="text" class="form__field" placeholder="Your Name" required v-model="getPartecipantName" maxlength="20" @keyup="errors = false" />
					<label for="name" class="form__label" @keyup="errors = false">{{ $t('insert_your_name') }}</label>
				</div>
				<div class="form__group">
					<input type="text" class="form__field" placeholder="Your Name" required v-model="getPartecipanSurname" maxlength="20" />
					<label for="surname" class="form__label">{{ $t('insert_your_lastname') }}</label>
				</div>
				<p class="form__group_error" :class="{ show: errors }">{{ $t('partecipant_already_exist') }}!</p>
			</div>

			<button type="submit" class="join-button">{{ $t('join_meet') }}</button>
		</form>

		<audio v-show="false" controls></audio>

		<div class="background-modal" :class="{ open: showBackgrounds }">
			<div class="modal-container">
				<div class="background-header">
					<span>Anteprima</span>
					<svg @click="closeModal()" style="width: 18px; height: 18px" viewBox="0 0 24 24">
						<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
					</svg>
				</div>
				<div class="background-opt">
					<div class="canvas-container">
						<canvas class="output_canvas_preview" style="aspect-ratio: 4/3; max-width: 80%; width: 100%"></canvas>
					</div>
					<div class="opt-container">
						<div class="no-effects" style="width: 100%; margin-bottom: 10px">{{ $t('no_effects') }}</div>
						<div class="blur">
							<div
								class="blur-choice"
								:class="{ selected: blur === null && background === null }"
								@click="
									() => {
										blur = 0
										background = null
										setImageSrc()
									}
								"
							>
								<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
									<path
										fill="currentColor"
										d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z"
									/>
								</svg>
								<div class="bg-tooltip">{{ $t('no_effects') }}</div>
							</div>
							<div
								class="blur-choice"
								style="position: relative"
								:class="{ selected: blur === 0.5 }"
								@click="
									() => {
										blur = 0.5
									}
								"
							>
								<svg style="width: 29px; height: 29px" viewBox="0 0 24 24">
									<path
										fill="currentColor"
										d="M14,8.5A1.5,1.5 0 0,0 12.5,10A1.5,1.5 0 0,0 14,11.5A1.5,1.5 0 0,0 15.5,10A1.5,1.5 0 0,0 14,8.5M14,12.5A1.5,1.5 0 0,0 12.5,14A1.5,1.5 0 0,0 14,15.5A1.5,1.5 0 0,0 15.5,14A1.5,1.5 0 0,0 14,12.5M10,17A1,1 0 0,0 9,18A1,1 0 0,0 10,19A1,1 0 0,0 11,18A1,1 0 0,0 10,17M10,8.5A1.5,1.5 0 0,0 8.5,10A1.5,1.5 0 0,0 10,11.5A1.5,1.5 0 0,0 11.5,10A1.5,1.5 0 0,0 10,8.5M14,20.5A0.5,0.5 0 0,0 13.5,21A0.5,0.5 0 0,0 14,21.5A0.5,0.5 0 0,0 14.5,21A0.5,0.5 0 0,0 14,20.5M14,17A1,1 0 0,0 13,18A1,1 0 0,0 14,19A1,1 0 0,0 15,18A1,1 0 0,0 14,17M21,13.5A0.5,0.5 0 0,0 20.5,14A0.5,0.5 0 0,0 21,14.5A0.5,0.5 0 0,0 21.5,14A0.5,0.5 0 0,0 21,13.5M18,5A1,1 0 0,0 17,6A1,1 0 0,0 18,7A1,1 0 0,0 19,6A1,1 0 0,0 18,5M18,9A1,1 0 0,0 17,10A1,1 0 0,0 18,11A1,1 0 0,0 19,10A1,1 0 0,0 18,9M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,13A1,1 0 0,0 17,14A1,1 0 0,0 18,15A1,1 0 0,0 19,14A1,1 0 0,0 18,13M10,12.5A1.5,1.5 0 0,0 8.5,14A1.5,1.5 0 0,0 10,15.5A1.5,1.5 0 0,0 11.5,14A1.5,1.5 0 0,0 10,12.5M10,7A1,1 0 0,0 11,6A1,1 0 0,0 10,5A1,1 0 0,0 9,6A1,1 0 0,0 10,7M10,3.5A0.5,0.5 0 0,0 10.5,3A0.5,0.5 0 0,0 10,2.5A0.5,0.5 0 0,0 9.5,3A0.5,0.5 0 0,0 10,3.5M10,20.5A0.5,0.5 0 0,0 9.5,21A0.5,0.5 0 0,0 10,21.5A0.5,0.5 0 0,0 10.5,21A0.5,0.5 0 0,0 10,20.5M3,13.5A0.5,0.5 0 0,0 2.5,14A0.5,0.5 0 0,0 3,14.5A0.5,0.5 0 0,0 3.5,14A0.5,0.5 0 0,0 3,13.5M14,3.5A0.5,0.5 0 0,0 14.5,3A0.5,0.5 0 0,0 14,2.5A0.5,0.5 0 0,0 13.5,3A0.5,0.5 0 0,0 14,3.5M14,7A1,1 0 0,0 15,6A1,1 0 0,0 14,5A1,1 0 0,0 13,6A1,1 0 0,0 14,7M21,10.5A0.5,0.5 0 0,0 21.5,10A0.5,0.5 0 0,0 21,9.5A0.5,0.5 0 0,0 20.5,10A0.5,0.5 0 0,0 21,10.5M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M3,9.5A0.5,0.5 0 0,0 2.5,10A0.5,0.5 0 0,0 3,10.5A0.5,0.5 0 0,0 3.5,10A0.5,0.5 0 0,0 3,9.5M6,9A1,1 0 0,0 5,10A1,1 0 0,0 6,11A1,1 0 0,0 7,10A1,1 0 0,0 6,9M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,13A1,1 0 0,0 5,14A1,1 0 0,0 6,15A1,1 0 0,0 7,14A1,1 0 0,0 6,13Z"
									/>
								</svg>
								<div class="bg-tooltip">{{ $t('light_blur') }}</div>
							</div>
							<div
								class="blur-choice"
								:class="{ selected: blur === 1.5 }"
								@click="
									() => {
										blur = 1.5
									}
								"
							>
								<svg style="width: 29px; height: 29px" viewBox="0 0 24 24">
									<path
										fill="currentColor"
										d="M14,8.5A1.5,1.5 0 0,0 12.5,10A1.5,1.5 0 0,0 14,11.5A1.5,1.5 0 0,0 15.5,10A1.5,1.5 0 0,0 14,8.5M14,12.5A1.5,1.5 0 0,0 12.5,14A1.5,1.5 0 0,0 14,15.5A1.5,1.5 0 0,0 15.5,14A1.5,1.5 0 0,0 14,12.5M10,17A1,1 0 0,0 9,18A1,1 0 0,0 10,19A1,1 0 0,0 11,18A1,1 0 0,0 10,17M10,8.5A1.5,1.5 0 0,0 8.5,10A1.5,1.5 0 0,0 10,11.5A1.5,1.5 0 0,0 11.5,10A1.5,1.5 0 0,0 10,8.5M14,20.5A0.5,0.5 0 0,0 13.5,21A0.5,0.5 0 0,0 14,21.5A0.5,0.5 0 0,0 14.5,21A0.5,0.5 0 0,0 14,20.5M14,17A1,1 0 0,0 13,18A1,1 0 0,0 14,19A1,1 0 0,0 15,18A1,1 0 0,0 14,17M21,13.5A0.5,0.5 0 0,0 20.5,14A0.5,0.5 0 0,0 21,14.5A0.5,0.5 0 0,0 21.5,14A0.5,0.5 0 0,0 21,13.5M18,5A1,1 0 0,0 17,6A1,1 0 0,0 18,7A1,1 0 0,0 19,6A1,1 0 0,0 18,5M18,9A1,1 0 0,0 17,10A1,1 0 0,0 18,11A1,1 0 0,0 19,10A1,1 0 0,0 18,9M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,13A1,1 0 0,0 17,14A1,1 0 0,0 18,15A1,1 0 0,0 19,14A1,1 0 0,0 18,13M10,12.5A1.5,1.5 0 0,0 8.5,14A1.5,1.5 0 0,0 10,15.5A1.5,1.5 0 0,0 11.5,14A1.5,1.5 0 0,0 10,12.5M10,7A1,1 0 0,0 11,6A1,1 0 0,0 10,5A1,1 0 0,0 9,6A1,1 0 0,0 10,7M10,3.5A0.5,0.5 0 0,0 10.5,3A0.5,0.5 0 0,0 10,2.5A0.5,0.5 0 0,0 9.5,3A0.5,0.5 0 0,0 10,3.5M10,20.5A0.5,0.5 0 0,0 9.5,21A0.5,0.5 0 0,0 10,21.5A0.5,0.5 0 0,0 10.5,21A0.5,0.5 0 0,0 10,20.5M3,13.5A0.5,0.5 0 0,0 2.5,14A0.5,0.5 0 0,0 3,14.5A0.5,0.5 0 0,0 3.5,14A0.5,0.5 0 0,0 3,13.5M14,3.5A0.5,0.5 0 0,0 14.5,3A0.5,0.5 0 0,0 14,2.5A0.5,0.5 0 0,0 13.5,3A0.5,0.5 0 0,0 14,3.5M14,7A1,1 0 0,0 15,6A1,1 0 0,0 14,5A1,1 0 0,0 13,6A1,1 0 0,0 14,7M21,10.5A0.5,0.5 0 0,0 21.5,10A0.5,0.5 0 0,0 21,9.5A0.5,0.5 0 0,0 20.5,10A0.5,0.5 0 0,0 21,10.5M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M3,9.5A0.5,0.5 0 0,0 2.5,10A0.5,0.5 0 0,0 3,10.5A0.5,0.5 0 0,0 3.5,10A0.5,0.5 0 0,0 3,9.5M6,9A1,1 0 0,0 5,10A1,1 0 0,0 6,11A1,1 0 0,0 7,10A1,1 0 0,0 6,9M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,13A1,1 0 0,0 5,14A1,1 0 0,0 6,15A1,1 0 0,0 7,14A1,1 0 0,0 6,13Z"
									/>
								</svg>
								<div class="bg-tooltip">{{ $t('mid_blur') }}</div>
							</div>
							<div
								class="blur-choice"
								:class="{ selected: blur === 5 }"
								@click="
									() => {
										blur = 5
									}
								"
							>
								<svg style="width: 34px; height: 34px" viewBox="0 0 24 24">
									<path
										fill="currentColor"
										d="M14,8.5A1.5,1.5 0 0,0 12.5,10A1.5,1.5 0 0,0 14,11.5A1.5,1.5 0 0,0 15.5,10A1.5,1.5 0 0,0 14,8.5M14,12.5A1.5,1.5 0 0,0 12.5,14A1.5,1.5 0 0,0 14,15.5A1.5,1.5 0 0,0 15.5,14A1.5,1.5 0 0,0 14,12.5M10,17A1,1 0 0,0 9,18A1,1 0 0,0 10,19A1,1 0 0,0 11,18A1,1 0 0,0 10,17M10,8.5A1.5,1.5 0 0,0 8.5,10A1.5,1.5 0 0,0 10,11.5A1.5,1.5 0 0,0 11.5,10A1.5,1.5 0 0,0 10,8.5M14,20.5A0.5,0.5 0 0,0 13.5,21A0.5,0.5 0 0,0 14,21.5A0.5,0.5 0 0,0 14.5,21A0.5,0.5 0 0,0 14,20.5M14,17A1,1 0 0,0 13,18A1,1 0 0,0 14,19A1,1 0 0,0 15,18A1,1 0 0,0 14,17M21,13.5A0.5,0.5 0 0,0 20.5,14A0.5,0.5 0 0,0 21,14.5A0.5,0.5 0 0,0 21.5,14A0.5,0.5 0 0,0 21,13.5M18,5A1,1 0 0,0 17,6A1,1 0 0,0 18,7A1,1 0 0,0 19,6A1,1 0 0,0 18,5M18,9A1,1 0 0,0 17,10A1,1 0 0,0 18,11A1,1 0 0,0 19,10A1,1 0 0,0 18,9M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,13A1,1 0 0,0 17,14A1,1 0 0,0 18,15A1,1 0 0,0 19,14A1,1 0 0,0 18,13M10,12.5A1.5,1.5 0 0,0 8.5,14A1.5,1.5 0 0,0 10,15.5A1.5,1.5 0 0,0 11.5,14A1.5,1.5 0 0,0 10,12.5M10,7A1,1 0 0,0 11,6A1,1 0 0,0 10,5A1,1 0 0,0 9,6A1,1 0 0,0 10,7M10,3.5A0.5,0.5 0 0,0 10.5,3A0.5,0.5 0 0,0 10,2.5A0.5,0.5 0 0,0 9.5,3A0.5,0.5 0 0,0 10,3.5M10,20.5A0.5,0.5 0 0,0 9.5,21A0.5,0.5 0 0,0 10,21.5A0.5,0.5 0 0,0 10.5,21A0.5,0.5 0 0,0 10,20.5M3,13.5A0.5,0.5 0 0,0 2.5,14A0.5,0.5 0 0,0 3,14.5A0.5,0.5 0 0,0 3.5,14A0.5,0.5 0 0,0 3,13.5M14,3.5A0.5,0.5 0 0,0 14.5,3A0.5,0.5 0 0,0 14,2.5A0.5,0.5 0 0,0 13.5,3A0.5,0.5 0 0,0 14,3.5M14,7A1,1 0 0,0 15,6A1,1 0 0,0 14,5A1,1 0 0,0 13,6A1,1 0 0,0 14,7M21,10.5A0.5,0.5 0 0,0 21.5,10A0.5,0.5 0 0,0 21,9.5A0.5,0.5 0 0,0 20.5,10A0.5,0.5 0 0,0 21,10.5M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M3,9.5A0.5,0.5 0 0,0 2.5,10A0.5,0.5 0 0,0 3,10.5A0.5,0.5 0 0,0 3.5,10A0.5,0.5 0 0,0 3,9.5M6,9A1,1 0 0,0 5,10A1,1 0 0,0 6,11A1,1 0 0,0 7,10A1,1 0 0,0 6,9M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,13A1,1 0 0,0 5,14A1,1 0 0,0 6,15A1,1 0 0,0 7,14A1,1 0 0,0 6,13Z"
									/>
								</svg>
								<div class="bg-tooltip">{{ $t('high_blur') }}</div>
							</div>
						</div>
						<div style="width: 100%; margin-top: 10px; margin-bottom: -15px">{{ $t('backgrounds') }}</div>

						<div class="backgrounds-container-items">
							<div
								class="background-choice"
								:class="{ selected: background === bg }"
								@click="
									() => {
										background = bg
										setImageSrc()
									}
								"
								v-for="(bg, index) in backgrounds"
								:key="index"
								:style="'background:url(' + bg.image + ')'"
							>
								<div class="bg-tooltip" v-text="bg.tooltip" />
							</div>
						</div>
					</div>
				</div>
				<div class="background-footer">
					<button @click="setMediaPipe">{{ $t('save_settings') }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { io } from 'socket.io-client'
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation'
import { Camera } from '@mediapipe/camera_utils'
import i18n from './../../i18n'

export default {
	data() {
		return {
			errors: false,
			meetName: this.$t('video_meet'),
			enableVideo: false,
			enableAudio: false,
			stream: null,
			partecipants: [],
			partecipantName: '',
			partecipantSurname: '',
			socket: null,
			showBackgrounds: false,
			mediapipe: false,
			background: null,
			blur: null,
			backgrounds: [
				{ image: '/backgrounds/office1.jpg', tooltip: 'Contemporarary open space' },
				{ image: '/backgrounds/office2.jpg', tooltip: 'Cozy workplace at home' },
				{ image: '/backgrounds/office3.jpg', tooltip: 'Simple office' },
				{ image: '/backgrounds/office4.jpg', tooltip: 'Houseplants background' },
				{ image: '/backgrounds/office5.jpg', tooltip: 'Creative office' },
				{ image: '/backgrounds/office6.jpg', tooltip: 'Modern office' },
				{ image: '/backgrounds/office7.jpg', tooltip: 'Medical office' },
				{ image: '/backgrounds/office8.jpg', tooltip: 'Modern white office' },
				{ image: '/backgrounds/office9.jpg', tooltip: 'Office background' },
				{ image: '/backgrounds/office10.jpg', tooltip: 'Office interior' },
				{ image: '/backgrounds/office11.jpg', tooltip: 'Office with various plants' },
				{ image: '/backgrounds/office12.jpg', tooltip: 'Office room with a white background' }
			],
			cameraMediaPipe: null,
			image: null,
			selfieStart: false
		}
	},
	props: {
		socketURL: {
			type: String,
			// default: 'https://vue-webrtc-lobby.gcp.cving.com/'
			default: 'http://localhost:4001'
		}
	},
	mounted() {
		i18n.locale = this.getUrlParameter('lang') || 'it'

		this.getStream('video')
		this.getStream('audio')

		this.socket = io(this.socketURL + 'preview', {
			rejectUnauthorized: false,
			transports: ['polling', 'websocket']
		})

		// check current partecipants
		this.socket.emit('enteredPreview', this.getMeetKey())

		// listen on new joined partecipant
		this.socket.on('joined', (meet) => {
			if (!meet) this.partecipants = []
			else this.partecipants = meet.partecipants.filter((f) => !f.isScreenShare)
		})
	},
	computed: {
		getPartecipantName: {
			get: function () {
				return this.partecipantName
			},
			set: function (newValue) {
				this.partecipantName = newValue.charAt(0).toUpperCase() + newValue.slice(1)
			}
		},
		getPartecipanSurname: {
			get: function () {
				return this.partecipantSurname
			},
			set: function (newValue) {
				this.partecipantSurname = newValue.charAt(0).toUpperCase() + newValue.slice(1)
			}
		},
		getPartecipantDescription() {
			if (!this.partecipants.length) return this.$t('no_partecipants')

			if (this.partecipants.length === 1) {
				return `${this.partecipants[0].name.charAt(0).toUpperCase() + this.partecipants[0].name.slice(1) + ' '} ${this.$t('is_partecipating')}`
			}

			if (this.partecipants.length < 4) {
				return `${this.partecipants
					.map((m) => m.name.charAt(0).toUpperCase() + m.name.slice(1))
					.slice(0, -1)
					.join(', ')} ${this.$t('and')} ${
					this.partecipants[this.partecipants.length - 1].name.charAt(0).toUpperCase() + this.partecipants[this.partecipants.length - 1].name.slice(1)
				} ${this.$t('are_partecipating')}`
			}

			if (this.partecipants.length - 3 === 1) {
				return `${this.partecipants
					.map((m) => m.name.charAt(0).toUpperCase() + m.name.slice(1))
					.slice(0, 3)
					.join(', ')} ${this.$t('and_one_more_is_partecipating')}`
			}

			return `${this.partecipants
				.map((m) => m.name.charAt(0).toUpperCase() + m.name.slice(1))
				.slice(0, 3)
				.join(', ')} ${this.$t('and_others')} ${this.partecipants.length - 3} ${this.$t('people_are_partecipating')}`
		}
	},
	methods: {
		/**
		 * extract a parameter value from the page url using a given parameter `name`
		 * @param name the parameter name to search for
		 * @returns the value of the parameter
		 */
		getUrlParameter(name) {
			// name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
		},
		getMeetKey() {
			return window.location.href.split('meet/').pop().split('?')[0]
		},
		getName(item) {
			if (item.name) {
				let name = item.name.split(' ')
				name = name[0].substring(0, 1).toUpperCase() + name[1].substring(0, 1).toUpperCase()
				return name
			}
			return ''
		},
		toggleMediaPipe() {
			this.showBackgrounds = !this.showBackgrounds
			if (this.showBackgrounds) {
				this.selfieStart = true
				this.start()
			}
		},
		start() {
			const videoElement = document.getElementsByClassName('input_video')[0]
			const canvasElement = !this.showBackgrounds ? document.getElementsByClassName('output_canvas')[0] : document.getElementsByClassName('output_canvas_preview')[0]
			const canvasCtx = canvasElement.getContext('2d')

			let selfieSegmentation = new SelfieSegmentation({
				locateFile: (file) => {
					return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
				}
			})
			selfieSegmentation.setOptions({
				modelSelection: 1,
				selfieMode: true
			})
			selfieSegmentation.onResults((results) => {
				this.onResults(canvasCtx, canvasElement, results)
			})

			this.cameraMediaPipe = new Camera(videoElement, {
				onFrame: async () => {
					if (this.selfieStart && selfieSegmentation) {
						await selfieSegmentation.initialize()
						await selfieSegmentation.send({ image: videoElement })
					} else {
						if (selfieSegmentation) {
							selfieSegmentation.close()
							selfieSegmentation = null
						}
					}
				},
				width: 1280,
				height: 720
			})
			this.cameraMediaPipe.start()
		},
		onResults(canvasCtx, canvasElement, results) {
			canvasCtx.save()
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height)
			canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height)

			// Only overwrite existing pixels.
			canvasCtx.globalCompositeOperation = 'source-in'
			canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height)

			// Only overwrite missing pixels.
			canvasCtx.globalCompositeOperation = 'destination-atop'
			canvasCtx.filter = 'blur(' + this.blur + 'px)'

			if (this.image) {
				canvasCtx.drawImage(this.image, 0, 0, canvasElement.width, canvasElement.height)
			} else {
				canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height)
			}
			canvasCtx.restore()
		},
		setImageSrc() {
			this.image = null
			let i = null
			i = document.createElement('img')
			i.src = this.background.image
			this.image = i
		},
		setMediaPipe() {
			this.showBackgrounds = false
			if ((this.background && this.background.image !== '') || (this.blur && this.blur > 0)) {
				this.start()
				this.mediapipe = true
			} else {
				this.mediapipe = false
				this.selfieStart = false
			}
			this.showBackgrounds = false
		},
		closeModal() {
			if ((!this.background || this.background.image === '') && (!this.blur || this.blur <= 0)) {
				this.selfieStart = false
			}
			this.showBackgrounds = false
		},
		toggleVideo() {
			this.enableVideo = !this.enableVideo
			if (this.enableVideo) {
				this.getStream('video')
			} else {
				this.mediapipe = false
				this.stream.getTracks()[0].stop()
				document.querySelector('video').srcObject = null
			}
		},
		toggleAudio() {
			this.enableAudio = !this.enableAudio
			if (this.enableAudio) {
				this.getStream('audio')
			} else {
				this.stream.getTracks()[0].stop()
				document.querySelector('audio').srcObject = null
			}
		},
		getStream(type) {
			var constraints = {}
			constraints[type] = true

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					var mediaControl = document.querySelector(type)

					if (navigator.mozGetUserMedia) {
						mediaControl.mozSrcObject = stream
					} else {
						mediaControl.srcObject = stream
						// mediaControl.src = (
						// 	window.URL || window.webkitURL
						// ).createObjectURL(stream);
					}

					if (type === 'video') {
						this.enableVideo = true
					} else {
						this.enableAudio = true
					}

					this.stream = stream
				})
				.catch(() => {
					if (type === 'video') {
						this.enableVideo = false
					} else {
						this.enableAudio = false
					}
				})
		},
		onJoin() {
			if (this.partecipantName && this.partecipantSurname) {
				const alreadyExist = this.partecipants.find((p) => {
					return p.name === this.partecipantName.trim() + ' ' + this.partecipantSurname.trim()
				})

				if (!alreadyExist) {
					const blur = this.blur >= 0 ? this.blur : 0
					const src = this.background && this.background.image ? this.background.image : ''

					this.$emit('join', {
						partecipantName: this.partecipantName.trim() + ' ' + this.partecipantSurname.trim(),
						enableVideo: this.enableVideo,
						enableAudio: this.enableAudio,
						meetName: this.meetName,
						blur: blur,
						src: src
					})

					if (this.enableVideo) {
						this.toggleVideo()
					}
				} else {
					this.errors = true
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.container {
	user-select: none;
	display: flex;
	height: 100%;
	margin-top: -54px;
	align-items: center;
	justify-content: center;
	position: relative;

	.video-container {
		position: relative;

		video {
			transform: rotateY(180deg) !important;
			-webkit-transform: rotateY(180deg) !important;
			-moz-transform: rotateY(180deg) !important;
			&::-webkit-media-controls-panel {
				display: none !important;
				opacity: 0 !important;
			}
			object-fit: cover;
			border-radius: 15px;
		}

		.video-container-actions {
			position: absolute;
			bottom: 15px;
			display: flex;
			justify-content: center;
			width: 100%;
			gap: 20px;

			button {
				border: 1px solid #01c9fa;
				border-radius: 50%;
				width: 40px;
				height: 40px;
				background: hsla(210, 65%, 67%, 0.25);
				justify-content: center;
				display: flex;
				align-items: center;
				color: white;
				backdrop-filter: blur(50px);
				cursor: pointer;
				&:hover {
					opacity: 0.85;
					cursor: pointer;
					background: hsla(210, 65%, 67%, 0.55) !important;
				}
				svg {
					width: 26px;
					height: 26px;
					path {
						fill: white;
					}
				}

				&.media-action-button-disabled {
					opacity: 0.5;
					border: 1px solid white;
					// background-color: #ff7019;
					background: transparent;
					svg path {
						fill: white;
					}
				}
				&:nth-child(3) {
					position: absolute;
					right: 10px;
				}
			}
		}

		.video-disabled-placeholder {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 1.2rem;
		}
		.output_canvas {
			border-radius: 15px;
		}
	}

	.meet-information {
		margin: 0 10px;
		align-items: center;
		display: flex;
		flex-direction: column;
		align-content: center;
		gap: 2rem;
		flex: 1;
		max-width: 500px;
		height: 100%;
		//max-height: 400px;
		justify-content: center;

		.meet-title {
			color: white;
			font-size: 2rem;
			font-weight: bold;
			text-align: center;
			margin-bottom: 1rem;
		}

		.meet-partecipants {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			color: whitesmoke;

			.meet-partecipants-list-item {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				font-size: 1.5rem;
				justify-content: center;

				.avatar {
					text-transform: uppercase;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 13px;
					font-weight: 600;
					color: white;
				}
			}
		}

		.join-button {
			//text-transform: uppercase;
			border-radius: 4px;
			min-width: 64px;
			background-color: #7979de;
			color: white;
			font-size: 18px;
			font-weight: 800;
			border: none;
			padding: 10px 30px;
			box-shadow: rgba(0,128,211, 0.3) 0px 1px 1px 0px, rgba(0,128,211, 0.15) 0px 1px 2px 1px;

			&:hover {
				background-color: #7979def0;
				cursor: pointer;
				box-shadow: rgba(8, 94, 151, 0.3) 0px 1px 2px 0px, rgba(0, 109, 181, 0.15) 0px 1px 3px 1px;
			}
		}

		.form__group {
			width: 100%;
			position: relative;
			padding: 15px 0 0;
			margin-top: 10px;
			&:nth-child(1) {
				margin-right: 5px;
			}
			&:nth-child(2) {
				margin-left: 5px;
			}

			.form__field {
				font-family: 'Lato', sans-serif;
				width: 100%;
				border: 0;
				border-bottom: 1px solid #d2d2d2;
				outline: 0;
				font-size: 16px;
				color: #ffffff;
				padding: 7px 2.5px;
				background: transparent;
				transition: border-color 0.2s;

				&::placeholder {
					color: transparent;
				}
			}
		}

		.form__field:placeholder-shown ~ .form__label {
			font-size: 15px;
			cursor: text;
			top: 25px;
			z-index: -1;
		}

		label,
		.form__field:focus ~ .form__label {
			position: absolute;
			top: 0;
			display: block;
			transition: 0.2s;
			font-size: 12px;
			color:rgb(199, 199, 199);
		}

		.form__field:focus ~ .form__label {
			color: #009788;
			color: #8484f2;
		}

		.form__field:focus {
			padding-bottom: 6px;
			border-bottom: 2px solid #009788;
			border-bottom: 2px solid #7979de;
		}
	}
}

.background-modal {
	position: absolute;
	top: 54px;
	bottom: 0;
	left: 0;
	right: 0;
	backdrop-filter: blur(10px);
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: space-between;
	justify-items: center;
	z-index: -1;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;

	*,
	> * {
		opacity: 0;
	}
	&.open {
		opacity: 1;
		z-index: 10;
		.modal-container {
			opacity: 1;
			transition: all 0.5 ease;
			animation: show 0.5s ease;
			*,
			> * {
				opacity: 1;
				transition: all 0.5 ease 0.2s;
			}
		}
	}

	.modal-container {
		max-width: 1000px;
		max-height: 500px;
		border-radius: 15px;
		margin: 70px 50px;
		background: white;
		display: flex;
		display: flex;
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 20px 25px;
		gap: 20px;
		div.background-footer {
			width: 100%;
			flex: 1;
			align-items: center;
			display: flex;
			justify-content: flex-end;
			button {
				outline: 0;
				border: 0;
				background: #0080d3;
				color: white;
				display: inline-block;
				position: relative;
				cursor: pointer;
				height: 35px;
				line-height: 35px;
				padding: 0 1.5rem;
				color: white;
				font-size: 14px;
				text-align: center;
				vertical-align: middle;
				white-space: nowrap;
				outline: none;
				border: none;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				border-radius: 2px;
				transition: all 0.3s ease-out;
				box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
			}
		}
		div.background-header {
			align-items: center;
			display: flex;
			font-size: 18px;
			justify-content: space-between;
			width: 100%;
		}
		div.background-opt {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}
	.canvas-container {
		flex: 0 0 60%;
		display: flex;
		height: 100%;
		justify-content: center;
		canvas {
			border-radius: 15px;
		}
	}
	.opt-container {
		padding-left: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 400px;
		flex: 1;
		overflow: hidden;
	}
	.blur {
		display: flex;
		position: relative;
		display: flex;
		.blur-choice {
			position: relative;
			cursor: pointer;
			display: flex;
			overflow: visible;
			justify-content: center;
			align-items: center;
			border: 1px solid rgba(128, 128, 128, 0.85);
			width: 10vh;
			height: 6vh;
			margin: 2px;
			border-radius: 5px;
			&:nth-child(2) {
				svg {
					opacity: 0.5;
				}
			}
			&:nth-child(3) {
				svg {
					opacity: 0.7;
				}
			}
			&.selected {
				background: hsla(210, 16%, 29%, 0.2);
				border: 2px solid #0080d3;
			}
		}
	}
	.backgrounds-container-items {
		padding-top: 20px;
		display: flex;
		flex-wrap: wrap;
		.background-choice {
			display: flex;
			justify-content: center;
			overflow: visible;
			position: relative;
			cursor: pointer;
			border: 2px solid hsla(210, 65%, 67%, 0.85);
			width: 10vh;
			height: 6vh;
			background-repeat: no-repeat !important;
			background-size: cover !important;
			margin: 2px;
			border-radius: 5px;
			&.selected {
				border: 2px solid #0080d3;
			}
		}
	}

	.bg-tooltip {
		opacity: 0 !important;
		position: absolute;
		top: 0px;
		left: -50%;
		right: 0;
	}
	.background-choice:hover,
	.blur-choice:hover {
		.bg-tooltip {
			opacity: 1 !important;
			z-index: 1;
			top: -20px;
			font-size: 12px;
			min-width: max-content;
			padding: 3px 6px;
			border-radius: 4px;
			text-align: center;
			color: white;
			background: #0080d3;
			transition: all 0.3 ease;
			animation: show 0.5s ease;
		}
	}
}
.form {
	gap: 10px;
	display: flex;
	position: relative;
	.form__group_error {
		position: absolute;
		bottom: -30px;
		color: #7979de;
		font-size: 13px;
		opacity: 0;
		transform: scale(0.8);
		transition: all 0.2s ease-in;
		&.show {
			transform: scale(1);
			opacity: 1;
			transition: all 0.3s ease-in;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.background-modal {
		&.open {
			bottom: 54px;
			top: 0;
		}
	}
	.container {
		margin: 0 !important;
		padding: 0 !important;
		justify-content: flex-start;
		flex-direction: column;
		transition: all 0.3 ease;
		overflow: hidden;
		overflow-y: auto;
		transition: all 0.3 ease !important;
	}
	.video-container {
		margin: 20px 0;
		transition: all 0.3 ease !important;
		video {
			max-width: 350px;
			max-height: 262.5px;
			transition: all 0.3 ease !important;
		}
	}
	.meet-information {
		transition: all 0.3 ease !important;
		padding-bottom: 70px;
		height: 100%;
		width: 100%;
		overflow: hidden !important;
	}
	.form {
		gap: unset;
		flex-direction: column;
		max-width: 350px;
		width: 100%;
		transition: all 0.3 ease;
		.form__group:first-child {
			transition: all 0.3 ease !important;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
	.background-opt {
		flex-direction: column;
		gap: 3px;
	}
	.container,
	.modal-container {
		overflow: hidden auto;
		top: 0;
		bottom: 0;
	}
	.modal-container {
		max-width: 1000px;
		max-height: 500px;
		border-radius: 15px;
		margin: 70px 50px;
		background: white;
		display: block !important;
		padding: 20px 25px;
		gap: 0;
		height: calc(100% - 160px) !important;
		overflow-y: auto !important;
	}
	.opt-container {
		justify-content: center;
		padding-left: 0 !important;
		margin: 15px 0;
	}
	.canvas-container {
		margin: auto;
		flex: 0;
		text-align: center;
		padding: 0;
		display: block !important;
	}
	.background-header {
		margin-bottom: 10px;
	}
	.blur + div,
	.no-effects {
		text-align: center;
		margin: 10px 0;
	}
	.backgrounds-container-items {
		overflow: hidden !important;
		height: 100% !important;
		justify-content: center;
	}
	.blur {
		justify-content: center;
	}
}
</style>
