

























































































































































































import Vue from 'vue'
import { api } from '../api'
import i18n from './../i18n'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { EventBus } from '@/event-bus'

import dayjs, { Dayjs } from 'dayjs'
require('dayjs/locale/it')
dayjs.locale(navigator.language || 'it')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

interface Slot {
	startTime: string
	endTime: string
	date: string
	day: number
	bookId: number
	selected?: boolean

	invitationReqId: string
	startDate: string
	endDate: string
	origEventId: number
	eventId: string
}

interface Day {
	dayID: number
	date: string
	year: string
	month: string
	day: string
	weekday: string
}

interface ApiSlotResultEventInvitations {
	creationDate: string
	eventDateFrom: string
	eventDateTo: string
	freeEventCount: number
	id: number
	requestId: string
	requestState: number
	uidRecruiter: number
}

interface ApiSlotResultEvent {
	title: string
	startDate: string
	endDate: string
	isRecurrencyMaster: boolean
	origEventId: number
	eventId: string
	isRecurrency: boolean
	rrule: any
	teamIds: any
	eventData: {
		id: number
		uidOwner: number
		uidRecruiter: number
		coid: number
		eventState: number
		eventType: number
		creationDate: string
		updateDate: any
		eventAttributes: any
	}
	candidates: any
	invitations: ApiSlotResultEventInvitations[]
	notifications: any
}

interface ApiSlotResult {
	events: ApiSlotResultEvent[]
	invitationAttributes: [
		{
			name: string
			value: string
		}
	]
	recruiterAvatar: string
	recruiterCompany: string
	recruiterLastName: string
	recruiterName: string
}

export default Vue.extend({
	name: 'Home',

	data() {
		return {
			step: -1, // 1 calendar, 2 confirm, 3 thanks, 4 request
			type: -1, // 1 multiple, 2 single
			earliestSlot: '18:00',
			slotNumber: 36,
			candidateUid: 0,
			chosenTime: dayjs().format('YYYY-MM-DD HH:mm'),
			chosenBookId: 0,
			timeZone: '',
			recruiter: {
				firstName: 'Nome recruiter',
				lastName: 'Cognome recruiter',
				company: 'Azienda recruiter',
				avatar: 'https://placekitten.com/80'
			},
			calendar: [] as Day[],
			slots: [] as Slot[],
			dayjs,

			slotOpened: {
				invitationReqId: '',
				startDate: '',
				endDate: '',
				origEventId: 0
			},

			unlockMessage: '', // message to send to recruiter when rejecting a slot,
			slotType: ''
		}
	},

	mounted() {
		i18n.locale = this.getUrlParameter('lang') || 'it'
		EventBus.$emit('showSpinner')

		this.timeZone = dayjs.tz.guess() + ' (' + dayjs().tz(dayjs.tz.guess()).format('z') + ')'

		this.getSlots()
	},

	computed: {
		noPrevSlots(): boolean {
			return this.slots.filter((el: { date: any }) => dayjs(el.date, 'DD-MM-YYYY').isBefore(dayjs(this.calendar[0].date, 'YYYY-MM-DD'))).length === 0
		},

		noNextSlots(): boolean {
			return this.slots.filter((el: { date: any }) => dayjs(el.date, 'DD-MM-YYYY').isAfter(dayjs(this.calendar[6].date, 'YYYY-MM-DD'))).length === 0
		}
	},

	methods: {
		/**
		 * extract a parameter value from the page url using a given parameter `name`
		 * @param name the parameter name to search for
		 * @returns the value of the parameter
		 */
		getUrlParameter(name: string) {
			// name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
		},

		openCVingSite() {
			window.open('https://www.cving.com/', '_blank')
		},

		/** initialize the weeks-calendar */
		fillCalendar(firstDate: Dayjs) {
			let now = null

			this.calendar = []

			for (let i = 0; i <= 6; i++) {
				now = firstDate.add(i, 'd')
				this.calendar.push({
					dayID: i,
					date: now.format('YYYY-MM-DD'),
					year: now.format('YYYY'),
					month: now.format('MMM'),
					day: now.format('DD'),
					weekday: now.format('ddd')
				})
			}
		},

		/** manage the previous day movement on the calendar */
		navigateToPrevWeek() {
			const { year, month, day } = this.calendar[0]
			const newDate = dayjs(`${year}/${month}/${day}`, 'YYYY/MMM/D').subtract(1, 'w')

			this.fillCalendar(newDate)

			// update the slots with the new week
			this.slots.forEach((c: Slot) => (c.day = c.day + 7))
		},

		/** manage the next day movement on the calendar */
		navigateToNextWeek() {
			const { year, month, day } = this.calendar[6]
			const newDate = dayjs(`${year}/${month}/${day}`, 'YYYY/MMM/D').add(1, 'd')

			this.fillCalendar(newDate)

			// update the slots with the new week
			this.slots.forEach((c: Slot) => (c.day = c.day - 7))
		},

		/**
		 * manage the click on a slot
		 * @param date the slot date
		 * @param startTime the slot start time
		 * @param bookId the slot book id
		 */
		onSlotClicked(invitationReqId: string, startDate: string, endDate: string, origEventId: number, eventId: string) {
			const clickedSlot = this.slots.find((el: Slot) => el.eventId === eventId)

			this.slotOpened = {
				invitationReqId,
				startDate,
				endDate,
				origEventId
			}

			this.chosenTime = startDate

			// check if any of the slots is already selected
			if (this.slots.find((el: Slot) => el.selected)) {
				// if the slot clicked is selected change the step to 5
				if (clickedSlot?.selected) {
					this.step = 5
				}

				return
			}

			this.step = 2
		},

		/** manage the click on the confirm button of the choosen slot */
		lockSlot() {
			EventBus.$emit('showSpinner')

			api.post('/candidate/events/lockevent', {
				invitationReqId: this.slotOpened.invitationReqId,
				startDate: this.slotOpened.startDate,
				endDate: this.slotOpened.endDate,
				origEventId: this.slotOpened.origEventId
			})
				.then(() => {
					this.step = 3
				})
				.catch((error) => {
					if (error.response?.data?.messageCode === 'BKG-013') {
						// slot already selected by other user
						this.step = 9
					} else if (error.response?.data?.messageCode === 'BKG-015') {
						// slot expired
						this.step = 10
					}
				})
				.finally(() => {
					EventBus.$emit('hideSpinner')
				})
		},

		/** manage the click on the calcel button of the choosen slot */
		unlockSlot() {
			EventBus.$emit('showSpinner')
			api.post('/candidate/events/unlockevent', {
				invitationReqId: this.slotOpened.invitationReqId,
				startDate: this.slotOpened.startDate,
				endDate: this.slotOpened.endDate,
				origEventId: this.slotOpened.origEventId,
				unlockMessage: this.unlockMessage
			})
				.then(() => (this.step = 8))
				.catch((error) => {
					if (error.response.status !== 200) {
						this.step = 9 // someone else has locked the slot
					} else if (error.response?.data?.messageCode === 'BKG-015') {
						// slot expired
						this.step = 10
					}
				})
				.finally(() => {
					EventBus.$emit('hideSpinner')
				})
		},

		getSlots() {
			const req_id = this.getUrlParameter('req_id')

			api.get<ApiSlotResult>(`/candidate/events?req_id=${req_id}`)
				.then(({ data }) => {
					// rimosso utc temporaneamente
					/* data.events = data.events.map(event => {
						event.startDate = dayjs.utc(event.startDate, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')
						event.endDate = dayjs.utc(event.endDate, 'DD-MM-YYYY HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss')
						return event
					}) */
					let events = data.events.sort((a, b) => a.startDate.localeCompare(b.startDate))

					// start date is formatted "DD-MM-YYYY HH:mm". filter out all past slots
					events = events.filter((slot) => dayjs(slot.startDate, 'DD-MM-YYYY HH:mm').isAfter(dayjs()))

					// remove locked slots if they are assigned to someone else
					events = events.filter((slot) => {
						// slot is free
						if (slot.candidates === null) return true

						// slot is potentially assigned to someone else
						if (slot.candidates.length) {
							// if the slot is rejected, it is free
							if (slot.candidates.find((candidate: any) => candidate.assignState === 2)) {
								return true
							}
							// candidate.assignState == 1 and reqid is the same the slot is still mine
							return slot.candidates.find((candidate: any) => candidate.assignState === 1 && slot.invitations[0]?.requestId === req_id)
						}

						return true
					})

					let firstSlotDate: dayjs.Dayjs | null = null

					let newDate = dayjs.utc()

					events.forEach((element: ApiSlotResultEvent) => {
						newDate = dayjs('2000/01/01 ' + dayjs(element.startDate, 'DD-MM-YYYY HH:mm').format('HH:mm'), 'YYYY/MM/DD HH:mm')

						// if there is no date set slot date to firstSlotDate
						if (!firstSlotDate) firstSlotDate = dayjs(element.startDate, 'DD-MM-YYYY HH:mm')

						// if there is a date before set that date
						else firstSlotDate = firstSlotDate.isBefore(dayjs(element.startDate, 'DD-MM-YYYY HH:mm')) ? firstSlotDate : dayjs(element.startDate, 'DD-MM-YYYY HH:mm')
					}, this)

					events.forEach((element: ApiSlotResultEvent) => {
						newDate = dayjs('2000/01/01 ' + dayjs(element.startDate, 'DD-MM-YYYY HH:mm').format('HH:mm'), 'YYYY/MM/DD HH:mm')

						this.slots.push({
							startTime: dayjs(element.startDate, 'DD-MM-YYYY HH:mm').format('HH:mm'),
							endTime: dayjs(element.endDate, 'DD-MM-YYYY HH:mm').format('HH:mm'),
							date: element.startDate,
							day: Math.floor(dayjs(element.startDate, 'DD-MM-YYYY HH:mm').diff(dayjs(firstSlotDate!.format('DD-MM-YYYY') + '00:00', 'DD-MM-YYYY HH:mm'), 'days')) + 1, // diff in days
							bookId: 0,
							selected: element.eventData.eventState === 2,

							invitationReqId: req_id,
							startDate: element.startDate,
							endDate: element.endDate,
							origEventId: element.origEventId,
							eventId: element.eventId
						})

						this.earliestSlot = dayjs(newDate, 'YYYY/MM/DD HH:mm').isBefore(dayjs('2000/01/01 ' + this.earliestSlot, 'YYYY/MM/DD HH:mm')) ? newDate.format('HH:mm') : this.earliestSlot
					}, this)

					this.fillCalendar(firstSlotDate || dayjs())
					console.log(firstSlotDate)

					this.recruiter = {
						firstName: data.recruiterName,
						lastName: data.recruiterLastName,
						company: data.recruiterCompany,
						avatar: data.recruiterAvatar
					}

					// colloquio_presenza colloquio_telefonico live_call
					this.slotType = data.invitationAttributes.find((f: { name: string; value: string }) => f.name === 'booking_type')!.value

					// if there is only an option for the user to select, jump to step 2 (confirm)
					if (this.slots.length === 1) {
						this.onSlotClicked(this.slots[0]!.invitationReqId, this.slots[0]!.startDate, this.slots[0]!.endDate, this.slots[0]!.origEventId, this.slots[0]!.eventId)
						this.step = this.type = 2
					} else {
						this.step = this.type = 1
					}

					if (this.slots.find((el: Slot) => el.selected)) {
						const slotSelected = this.slots.find((el: Slot) => el.selected)
						// if a slot is selected change the step to 5
						this.onSlotClicked(slotSelected!.invitationReqId, slotSelected!.startDate, slotSelected!.endDate, slotSelected!.origEventId, slotSelected!.eventId)
					}

					this.slotNumber = (dayjs('2000/01/01 23:59', 'HH:mm').diff(dayjs('2000/01/01 ' + this.earliestSlot, 'HH:mm'), 'minute') + 1) / 15
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 406) {
							this.step = 7
						}
					}
				})
				.finally(() => {
					EventBus.$emit('hideSpinner')
				})
		}
	}
})
