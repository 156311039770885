<template>
	<div style="height: 100%; overflow: hidden">
		<div class="loaderContainer" :class="{ 'loaderContainer--on': loading }">
			<div class="loader"></div>
		</div>
		<!-- <invalidMeet v-if="!verifiedParameter && !loading" :date="date" :deadline="deadline" /> -->
		<preview v-if="!meetJoined && verifiedParameter" @join="onJoin($event)" :socketURL="socketURL"> </preview>
		<!-- <meet v-show="meetJoined" :roomId="roomId" ref="meet"> </meet> -->
		<meet v-show="meetJoined && verifiedParameter" :roomId="roomId" ref="meet" :socketURL="socketURL"> </meet>
	</div>
</template>

<script>
// https://github.com/westonsoftware/vue-webrtc/blob/master/vue-webrtc-lobby/server.js

import Preview from '../components/meet/Preview.vue'
import vuewebrtc from '../components/meet/vue-webrtc.vue'
// import InvalidMeet from '../components/InvalidMeet.vue'
import { EventBus } from '@/event-bus'
import dayjs from 'dayjs'
import i18n from './../i18n'
dayjs.locale(navigator.language || 'it')

export default {
	name: 'App',
	data() {
		return {
			loading: true,
			verifiedParameter: false,
			meetJoined: false,
			roomId: '',
			socketURL: process.env.VUE_APP_SERVER,
			// socketURL: 'http://localhost:4001/',
			date: null,
			deadline: null
		}
	},
	components: {
		preview: Preview,
		meet: vuewebrtc
		// invalidMeet: InvalidMeet
	},
	created() {
		this.roomId = this.getMeetKey()
		setTimeout(() => {
			this.loading = true
			this.date = new Date('05-07-2022 16:40:00')
			this.deadline = dayjs(this.date)
			this.deadline = this.deadline.subtract(15, 'minute')
			const now = dayjs()
			const diff = this.deadline.diff(now, 'minutes')
			if (diff > 15 || 8 * -60 > diff) {
				this.deadline = dayjs(this.deadline).format('YYYY-MM-DD HH:mm:ss')
			} else {
				this.verifiedParameter = true
			}
			this.verifiedParameter = true
			this.loading = false
		}, 1000)
	},
	mounted() {
		i18n.locale = this.getUrlParameter('lang') || 'it'

		EventBus.$on('left-room', () => {
			this.meetJoined = false
		})
	},
	methods: {
		/**
		 * extract a parameter value from the page url using a given parameter `name`
		 * @param name the parameter name to search for
		 * @returns the value of the parameter
		 */
		getUrlParameter(name) {
			// name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
		},
		getMeetKey() {
			return window.location.href.split('meet/').pop().split('?')[0]
		},
		onJoin(partecipant) {
			this.meetJoined = !this.meetJoined

			if (this.meetJoined) {
				// this.$refs.meet.triggerJoin();
				this.$refs.meet.join(partecipant)
			}
		}
	}
}
</script>
<style scoped lang="scss">
.loaderContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	z-index: -1;
	align-items: center;
	justify-content: center;
	.loader {
		opacity: 0;
		transition: opacity 0.3s ease;
		width: 150px;
		height: 150px;
		animation: spin 2s linear infinite;
		background: url('../assets/circle.png') no-repeat;
		left: 45%;
		top: 45%;
		transform: translate(-45%, -45%);
		position: absolute;
	}
	&--on {
		background-color: rgba(0, 0, 0, 0.35);
		z-index: 10000;
		transition: background-color 0.3s ease 1s; /* Il secondo è il delay */

		.loader {
			left: 45%;
			top: 45%;
			transform: translate(-45%, -45%);
			position: absolute;
			opacity: 1;
			transition: opacity 0.3s ease;
			transition-delay: 0.1s;
		}
	}
}
@keyframes spin {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(360deg);
	}
}
</style>
