import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import EventInformation from '../views/EventInformation.vue'
import Meet from '../views/Meet.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/confirmed',
		name: 'EventInformationConfirmed',
		component: EventInformation,
		props: {
			type: 'confirm'
		}
	},
	{
		path: '/rejected',
		name: 'EventInformationRejected',
		component: EventInformation,
		props: {
			type: 'reject'
		}
	},
	{
		path: '/meet/:key',
		name: 'Meet',
		component: Meet
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
