<template>
	<div v-if="visible" class="loading-page">
		<div class="loading" />
	</div>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
	data() {
		return {
			visible: false
		}
	},
	mounted() {
		EventBus.$on('showSpinner', () => {
			this.visible = true
		})
		EventBus.$on('hideSpinner', () => {
			this.visible = false
		})
	}
}
</script>

<style lang="scss" scoped>
.loading-page {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1000;
	padding: 1rem;
	text-align: center;
	font-size: 3rem;
font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.loading {
		display: inline-block;
		width: 10rem;
		height: 10rem;
		border: 15px solid rgba(255, 255, 255, 0.705);
		border-radius: 50%;
		border-top-color: #fff;
		animation: spin 1s ease-in-out infinite;
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
</style>
