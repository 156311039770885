<template>
	<div class="Container">
		<div class="Toast" :class="{ show: showToast }">
			<div class="toast-container">
				<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
					<path fill="#f14c49" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
				</svg>
				<div>{{ getRecordingName }} {{ $t('partecipant_is_recording') }}</div>
			</div>
		</div>
		<div class="Scenary">
			<div class="Conference" :class="{ boxRightOpen: chatVisible || partecipantsVisible }" ref="conference">
				<div class="Screen" :class="{ pinned: videoPinned }" v-show="videoPinned">
					<div class="ScreenSharingOn" v-if="videoPinned && videoPinned.isScreenShare">
						<div class="ScreenSharingOn-container">
							<span>
								<svg viewBox="0 0 24 24" style="width: 22px; height: 22px; margin-right: 5px">
									<path fill="white" d="M23 4V16C23 17.11 22.11 18 21 18H15V16H21V4H3V16H9V18H3C1.9 18 1 17.11 1 16V4C1 2.89 1.89 2 3 2H21C22.1 2 23 2.89 23 4M13 13H16L12 9L8 13H11V20H8V22H16V20H13V13Z"></path>
								</svg>
								<span> {{ videoPinned.isLocal ? $t('you_are_streaming') : videoPinned.name.substring(0, 1).toUpperCase() + ' ' + $t('partecipant_is_streaming') }}</span>
							</span>
							<span>
								<button v-if="videoPinned.isLocal" @click="shareScreen()">{{ $t('stop_streaming') }}</button>
							</span>
						</div>
					</div>
					<div class="PinnedContainer">
						<span class="pin" @click="unexpand(videoPinned)" v-if="videoPinned"><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
								<path fill="white" d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
							</svg></span>
						<!-- <div v-if="!item.videoEnabled" class="container-initials">
						<span :class="'initials-audio-effect-' + item.id" />
						<span :class="'initials-audio-effect-big-' + item.id" />
						<span class="initials" :style="'background-color:' + item.avatarColor">
							{{ item.name ? item.name.substring(0, 1) : '' }}
						</span>
					</div> -->
						<video v-show="videoPinned && videoPinned.videoEnabled" :srcObject.prop="videoPinned ? videoPinned.stream : ''" v-bind:video="videoPinned" autoplay playsinline :muted="videoPinned ? videoPinned.muted : false" :id="videoPinned ? 'pinned_' + videoPinned.id : ''" class="video-pinned" :class="{ isScreenShare: videoPinned && videoPinned.isScreenShare }"></video>
						<canvas v-show="videoPinned && videoPinned.videoEnabled && (videoPinned.videoEffects.blur > 0 || videoPinned.videoEffects.src != '')" :class="videoPinned ? 'output_pinned_canvas_' + videoPinned.id : ''" style="width: 100%; height: 100%"></canvas>
						<div style="width:100%;height:100%;position relative" v-if="videoPinned && !videoPinned.videoEnabled">
							<div class="effect-container" v-show="videoPinned && !videoPinned.isScreenShare && videoPinned.audioEnabled">
								<div :class="'o1_' + videoPinned.id"></div>
								<div :class="'o2_' + videoPinned.id"></div>
								<div :class="'o3_' + videoPinned.id"></div>
							</div>
							<!-- audio muted visible only if is not a screenshare and islocal with audio off or !islocal with audio not enabled -->
							<span v-if="!videoPinned.isScreenShare && !videoPinned.audioEnabled" class="audio">
								<svg viewBox="0 0 24 24">
									<path d="M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z
									" />
								</svg>
							</span>
							<div v-if="!videoPinned.videoEnabled" class="container-initials">
								<span :class="'initials-audio-effect-' + videoPinned.id" />
								<span :class="'initials-audio-effect-big-' + videoPinned.id" />
								<span class="initials" :style="'background-color:' + videoPinned.avatarColor">
									{{ videoPinned.name ? videoPinned.name.substring(0, 1).toUpperCase() : '' }}
								</span>
							</div>
						</div>
						<span class="info">{{ videoPinned && videoPinned.name ? (videoPinned.isLocal ? 'Tu' : videoPinned.name) : '' }}</span>
					</div>
				</div>
				<div class="Dish" v-if="videoList.filter((f) => !f.pinned).length > 0">
					<div class="video-container" :class="item.videoEnabled ? '' : 'video-' + item.id" v-for="item in videoList.filter((f) => !f.pinned)" v-bind:key="item.id" :style="getVideoStyle">
						<span class="pin" @click="expand(item)">
							<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
								<path fill="white" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
							</svg>
						</span>
						<div class="effect-container" v-show="!item.isScreenShare && item.audioEnabled">
							<div :class="'o1_' + item.id"></div>
							<div :class="'o2_' + item.id"></div>
							<div :class="'o3_' + item.id"></div>
						</div>
						<!-- recording -->
						<span v-if="!item.isScreenShare && item.isRecording" class="recording">
							<svg viewBox="0 0 24 24">
								<path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z" />
							</svg>
						</span>
						<!-- audio muted visible only if is not a screenshare and islocal with audio off or !islocal with audio not enabled -->
						<span v-if="!item.isScreenShare && !item.audioEnabled" class="audio">
							<svg viewBox="0 0 24 24">
								<path d="M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z
									" />
							</svg>
						</span>
						<div v-if="!item.videoEnabled" class="container-initials">
							<span :class="'initials-audio-effect-' + item.id" />
							<span :class="'initials-audio-effect-big-' + item.id" />
							<span class="initials" :style="'background-color:' + item.avatarColor">
								{{ getName(item) }}
							</span>
						</div>
						<video v-show="item.videoEnabled" :srcObject.prop="item ? item.stream : ''" autoplay playsinline ref="videos" :muted="item.isLocal || item.muted" :id="item.id" :class="{ isScreenShare: item.isScreenShare }"></video>
						<canvas v-show="item.videoEnabled && ((item.videoEffects.blur && item.videoEffects.blur > 0) || item.videoEffects.src != '')" :class="'output_canvas_' + item.id" style="width: 100%; height: 100%"></canvas>
						<span class="info">{{ item.isLocal ? 'Tu' : item.name }} {{ item.isScreenShare ? ' (presentazione)' : '' }}</span>
					</div>
				</div>

				<div class="RightBox" v-on:keyup.enter="addMessage" :class="{ open: chatVisible || partecipantsVisible || showMediaPipe }">
					<div class="chat-container" v-show="chatVisible">
						<div class="chat-header">
							<span>Chat</span>
							<svg @click="manageRightBox('chat')" style="width: 20px; height: 20px" viewBox="0 0 24 24">
								<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
							</svg>
						</div>
						<div @click="showEmoji = false" class="chat-messages" id="chat-messages">
							<div class="chat-messages-info" :class="alreadyOpen ? 'alreadyOpen' : chatVisible ? 'show' : 'hide'">
								{{ $t('chat_info') }}
							</div>
							<div v-for="(message, index) in messages" :key="message.message + '_' + index" class="msg" :class="[message.message.id === videoList.find((f) => f.isLocal).id ? 'right-msg' : 'left-msg', { 'private-msg': message.private }]">
								<div class="msg-bubble" :style="getBubbleMinWidth(message.message.name + ' ' + message.message.time)">
									<div class="private-badge" v-show="message.private">
										<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
											<path fill="currentColor" d="M23 3V2.5C23 1.1 21.9 0 20.5 0S18 1.1 18 2.5V3C17.5 3 17 3.5 17 4V8C17 8.5 17.5 9 18 9H23C23.5 9 24 8.5 24 8V4C24 3.5 23.5 3 23 3M22 3H19V2.5C19 1.7 19.7 1 20.5 1S22 1.7 22 2.5V3M22 11V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2H15V4H4V17.2L5.2 16H20V11H22Z" />
										</svg>
									</div>
									<div class="msg-container">
										<div class="msg-info">
											<div class="msg-info-name" v-text="message.message.name" />
											<div class="msg-info-time" v-text="message.message.time" />
										</div>
										<div class="msg-text" v-html="message.message.text" />
									</div>
								</div>
							</div>
						</div>

						<div class="chat-input">
							<Emoji v-if="showEmoji" searchLabel="Search emoji here" @click="addEmoji" />
							<span class="chat-button-wrapper" :class="{ disabled: getPartecipants && getPartecipants.length === 0 }" @click="showSuggestion($event)" @keyup="moveUlFocus($event)">
								<button class="chat-button" :disabled="getPartecipants && getPartecipants.length === 0">
									<svg style="cursor: pointer; width: 24px; height: 24px" viewBox="0 0 24 24">
										<path :fill="textMessage.startsWith('@') ? 'rgba(0, 174, 255, 1)' : 'white'" d="M23 3V2.5C23 1.1 21.9 0 20.5 0S18 1.1 18 2.5V3C17.5 3 17 3.5 17 4V8C17 8.5 17.5 9 18 9H23C23.5 9 24 8.5 24 8V4C24 3.5 23.5 3 23 3M22 3H19V2.5C19 1.7 19.7 1 20.5 1S22 1.7 22 2.5V3M22 11V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2H15V4H4V17.2L5.2 16H20V11H22Z" />
									</svg>
								</button>
								<span class="chat-tooltip privateMessage" v-text="'Invia un messaggio privato'"></span>
							</span>

							<div style="position: relative; display: flex; flex: 1">
								<input autocomplete="do-not-autofill" id="field-chat-messages" @keyup="showSuggestion($event)" @focus="showEmoji = false" class="chat" v-model="textMessage" type="text" :placeholder="$t('write_message')" />
								<div class="suggestion" v-show="showPartecipants && getPartecipants.length > 0" :style="'top: -' + getTop + 'px'" @keyup="moveUlFocus($event)">
									<div>Messaggio privato</div>
									<ul id="ul-list-messages" @keyup="moveUlFocus($event)">
										<li @keyup="moveUlFocus($event)" @keyup.prevent.enter="setSuggestion(partecipant.name)" tabindex="-1" v-for="(partecipant, index) in getPartecipants" :key="index" @click="setSuggestion(partecipant.name)">
											@{{ partecipant.name }}
										</li>
									</ul>
								</div>
							</div>
							<button class="send" :disabled="textMessage === ''" @click="addMessage" v-on:keyup.prevent.enter="addMessage">
								<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
									<path fill="currentColor" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
								</svg>
							</button>
							<span class="chat-button-wrapper" @click="() => {
									showPartecipants = false
									showEmoji = !showEmoji
								}
								">
								<button class="chat-button">
									<svg style="cursor: pointer; width: 24px; height: 24px" viewBox="0 0 24 24">
										<path :fill="showEmoji ? 'rgba(0, 174, 255, 1)' : 'white'" d="M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M13,9.94L14.06,11L15.12,9.94L16.18,11L17.24,9.94L15.12,7.82L13,9.94M8.88,9.94L9.94,11L11,9.94L8.88,7.82L6.76,9.94L7.82,11L8.88,9.94M12,17.5C14.33,17.5 16.31,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5Z" />
									</svg>
								</button>
								<span class="chat-tooltip emojy" v-text="'Utilizza un emoji'"></span>
							</span>
						</div>
					</div>
					<div class="partecipants-container" v-show="partecipantsVisible">
						<div class="partecipants-header">
							<span>Partecipanti alla live call</span>
							<svg @click="manageRightBox('list')" style="width: 20px; height: 20px" viewBox="0 0 24 24">
								<path fill="white" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
							</svg>
						</div>
						<div class="partecipants-list">
							<div class="partecipants-info" :class="{ show: partecipantsVisible }" v-for="(user, index) in videoList.filter((u) => u.name && u.name != '')" :key="'list_' + index">
								<div class="badge-container">
									<span v-if="!user.isScreenShare" class="badge" :style="{ backgroundColor: user.avatarColor }"><span v-if="user.name">{{ user.name.substring(0, 1).toUpperCase() }}</span></span>
									<svg v-else viewBox="0 0 24 24" style="width: 25px!important; height: auto">
										<path fill="white" d="M23 4V16C23 17.11 22.11 18 21 18H15V16H21V4H3V16H9V18H3C1.9 18 1 17.11 1 16V4C1 2.89 1.89 2 3 2H21C22.1 2 23 2.89 23 4M13 13H16L12 9L8 13H11V20H8V22H16V20H13V13Z"></path>
									</svg>
								</div>
								<div class="name">{{ user.name }} {{ user.isScreenShare ? ' (presentazione)' : '' }}</div>
								<div class="pinned">
									<span @click="user.pinned ? unexpand(user) : expand(user)">
										<svg class="user-pin" style="width: 20px; height: 20px" viewBox="0 0 24 24">
											<path fill="white" :d="user.pinned
													? 'M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z'
													: 'M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z'
												" />
										</svg>
									</span>
								</div>
								<div class="audio">
									<span v-show="!user.audioEnabled">
										<svg class="user-audio" viewBox="0 0 24 24" style="width: 20px; height: 20px">
											<path fill="white" d="M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z
									" />
										</svg>
									</span>
									<div class="badge" v-show="user.audioEnabled">
										<div :class="'o4_' + user.id"></div>
										<div :class="'o5_' + user.id"></div>
										<div :class="'o6_' + user.id"></div>
									</div>
								</div>
								<div class="recording">
									<svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
										<path :fill="(recordOn && user.isLocal) || user.isRecording ? 'red' : 'white'" :d="(recordOn && user.isLocal) || user.isRecording
												? 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z'
												: 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z'
											" />
									</svg>
								</div>
							</div>
						</div>
					</div>

					<div class="background-container" v-show="showMediaPipe">
						<div class="background-header">
							<span>Effetti video</span>
							<svg @click="manageRightBox('background')" style="width: 20px; height: 20px" viewBox="0 0 24 24">
								<path fill="white" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
							</svg>
						</div>
						<div class="canvas-container">
							<canvas class="output_canvas_preview"></canvas>
						</div>

						<div class="scroller-container">
							<div class="background-title">{{ $t('blur') }}</div>

							<div class="blur">
								<div class="blur-choice" :class="{ selected: blur === 0 && background === null }" @click="() => {
										blur = 0
										background = null
										setImageSrc()
									}
									">
									<svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
										<path fill="currentColor" d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" />
									</svg>
									<div class="bg-tooltip">{{ $t('no_effects') }}</div>
								</div>
								<div class="blur-choice" style="position: relative" :class="{ selected: blur === 0.5 }" @click="() => {
										blur = 0.5
									}
									">
									<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
										<path fill="currentColor" d="M14,8.5A1.5,1.5 0 0,0 12.5,10A1.5,1.5 0 0,0 14,11.5A1.5,1.5 0 0,0 15.5,10A1.5,1.5 0 0,0 14,8.5M14,12.5A1.5,1.5 0 0,0 12.5,14A1.5,1.5 0 0,0 14,15.5A1.5,1.5 0 0,0 15.5,14A1.5,1.5 0 0,0 14,12.5M10,17A1,1 0 0,0 9,18A1,1 0 0,0 10,19A1,1 0 0,0 11,18A1,1 0 0,0 10,17M10,8.5A1.5,1.5 0 0,0 8.5,10A1.5,1.5 0 0,0 10,11.5A1.5,1.5 0 0,0 11.5,10A1.5,1.5 0 0,0 10,8.5M14,20.5A0.5,0.5 0 0,0 13.5,21A0.5,0.5 0 0,0 14,21.5A0.5,0.5 0 0,0 14.5,21A0.5,0.5 0 0,0 14,20.5M14,17A1,1 0 0,0 13,18A1,1 0 0,0 14,19A1,1 0 0,0 15,18A1,1 0 0,0 14,17M21,13.5A0.5,0.5 0 0,0 20.5,14A0.5,0.5 0 0,0 21,14.5A0.5,0.5 0 0,0 21.5,14A0.5,0.5 0 0,0 21,13.5M18,5A1,1 0 0,0 17,6A1,1 0 0,0 18,7A1,1 0 0,0 19,6A1,1 0 0,0 18,5M18,9A1,1 0 0,0 17,10A1,1 0 0,0 18,11A1,1 0 0,0 19,10A1,1 0 0,0 18,9M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,13A1,1 0 0,0 17,14A1,1 0 0,0 18,15A1,1 0 0,0 19,14A1,1 0 0,0 18,13M10,12.5A1.5,1.5 0 0,0 8.5,14A1.5,1.5 0 0,0 10,15.5A1.5,1.5 0 0,0 11.5,14A1.5,1.5 0 0,0 10,12.5M10,7A1,1 0 0,0 11,6A1,1 0 0,0 10,5A1,1 0 0,0 9,6A1,1 0 0,0 10,7M10,3.5A0.5,0.5 0 0,0 10.5,3A0.5,0.5 0 0,0 10,2.5A0.5,0.5 0 0,0 9.5,3A0.5,0.5 0 0,0 10,3.5M10,20.5A0.5,0.5 0 0,0 9.5,21A0.5,0.5 0 0,0 10,21.5A0.5,0.5 0 0,0 10.5,21A0.5,0.5 0 0,0 10,20.5M3,13.5A0.5,0.5 0 0,0 2.5,14A0.5,0.5 0 0,0 3,14.5A0.5,0.5 0 0,0 3.5,14A0.5,0.5 0 0,0 3,13.5M14,3.5A0.5,0.5 0 0,0 14.5,3A0.5,0.5 0 0,0 14,2.5A0.5,0.5 0 0,0 13.5,3A0.5,0.5 0 0,0 14,3.5M14,7A1,1 0 0,0 15,6A1,1 0 0,0 14,5A1,1 0 0,0 13,6A1,1 0 0,0 14,7M21,10.5A0.5,0.5 0 0,0 21.5,10A0.5,0.5 0 0,0 21,9.5A0.5,0.5 0 0,0 20.5,10A0.5,0.5 0 0,0 21,10.5M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M3,9.5A0.5,0.5 0 0,0 2.5,10A0.5,0.5 0 0,0 3,10.5A0.5,0.5 0 0,0 3.5,10A0.5,0.5 0 0,0 3,9.5M6,9A1,1 0 0,0 5,10A1,1 0 0,0 6,11A1,1 0 0,0 7,10A1,1 0 0,0 6,9M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,13A1,1 0 0,0 5,14A1,1 0 0,0 6,15A1,1 0 0,0 7,14A1,1 0 0,0 6,13Z" />
									</svg>

									<div class="bg-tooltip">{{ $t('light_blur') }}</div>
								</div>
								<div class="blur-choice" :class="{ selected: blur === 1.5 }" @click="() => {
										blur = 1.5
									}
									">
									<svg style="width: 26px; height: 26px" viewBox="0 0 24 24">
										<path fill="currentColor" d="M14,8.5A1.5,1.5 0 0,0 12.5,10A1.5,1.5 0 0,0 14,11.5A1.5,1.5 0 0,0 15.5,10A1.5,1.5 0 0,0 14,8.5M14,12.5A1.5,1.5 0 0,0 12.5,14A1.5,1.5 0 0,0 14,15.5A1.5,1.5 0 0,0 15.5,14A1.5,1.5 0 0,0 14,12.5M10,17A1,1 0 0,0 9,18A1,1 0 0,0 10,19A1,1 0 0,0 11,18A1,1 0 0,0 10,17M10,8.5A1.5,1.5 0 0,0 8.5,10A1.5,1.5 0 0,0 10,11.5A1.5,1.5 0 0,0 11.5,10A1.5,1.5 0 0,0 10,8.5M14,20.5A0.5,0.5 0 0,0 13.5,21A0.5,0.5 0 0,0 14,21.5A0.5,0.5 0 0,0 14.5,21A0.5,0.5 0 0,0 14,20.5M14,17A1,1 0 0,0 13,18A1,1 0 0,0 14,19A1,1 0 0,0 15,18A1,1 0 0,0 14,17M21,13.5A0.5,0.5 0 0,0 20.5,14A0.5,0.5 0 0,0 21,14.5A0.5,0.5 0 0,0 21.5,14A0.5,0.5 0 0,0 21,13.5M18,5A1,1 0 0,0 17,6A1,1 0 0,0 18,7A1,1 0 0,0 19,6A1,1 0 0,0 18,5M18,9A1,1 0 0,0 17,10A1,1 0 0,0 18,11A1,1 0 0,0 19,10A1,1 0 0,0 18,9M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,13A1,1 0 0,0 17,14A1,1 0 0,0 18,15A1,1 0 0,0 19,14A1,1 0 0,0 18,13M10,12.5A1.5,1.5 0 0,0 8.5,14A1.5,1.5 0 0,0 10,15.5A1.5,1.5 0 0,0 11.5,14A1.5,1.5 0 0,0 10,12.5M10,7A1,1 0 0,0 11,6A1,1 0 0,0 10,5A1,1 0 0,0 9,6A1,1 0 0,0 10,7M10,3.5A0.5,0.5 0 0,0 10.5,3A0.5,0.5 0 0,0 10,2.5A0.5,0.5 0 0,0 9.5,3A0.5,0.5 0 0,0 10,3.5M10,20.5A0.5,0.5 0 0,0 9.5,21A0.5,0.5 0 0,0 10,21.5A0.5,0.5 0 0,0 10.5,21A0.5,0.5 0 0,0 10,20.5M3,13.5A0.5,0.5 0 0,0 2.5,14A0.5,0.5 0 0,0 3,14.5A0.5,0.5 0 0,0 3.5,14A0.5,0.5 0 0,0 3,13.5M14,3.5A0.5,0.5 0 0,0 14.5,3A0.5,0.5 0 0,0 14,2.5A0.5,0.5 0 0,0 13.5,3A0.5,0.5 0 0,0 14,3.5M14,7A1,1 0 0,0 15,6A1,1 0 0,0 14,5A1,1 0 0,0 13,6A1,1 0 0,0 14,7M21,10.5A0.5,0.5 0 0,0 21.5,10A0.5,0.5 0 0,0 21,9.5A0.5,0.5 0 0,0 20.5,10A0.5,0.5 0 0,0 21,10.5M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M3,9.5A0.5,0.5 0 0,0 2.5,10A0.5,0.5 0 0,0 3,10.5A0.5,0.5 0 0,0 3.5,10A0.5,0.5 0 0,0 3,9.5M6,9A1,1 0 0,0 5,10A1,1 0 0,0 6,11A1,1 0 0,0 7,10A1,1 0 0,0 6,9M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,13A1,1 0 0,0 5,14A1,1 0 0,0 6,15A1,1 0 0,0 7,14A1,1 0 0,0 6,13Z" />
									</svg>
									<div class="bg-tooltip">{{ $t('mid_blur') }}</div>
								</div>
								<div class="blur-choice" :class="{ selected: blur === 5 }" @click="() => {
										blur = 5
									}
									">
									<svg style="width: 34px; height: 34px" viewBox="0 0 24 24">
										<path fill="currentColor" d="M14,8.5A1.5,1.5 0 0,0 12.5,10A1.5,1.5 0 0,0 14,11.5A1.5,1.5 0 0,0 15.5,10A1.5,1.5 0 0,0 14,8.5M14,12.5A1.5,1.5 0 0,0 12.5,14A1.5,1.5 0 0,0 14,15.5A1.5,1.5 0 0,0 15.5,14A1.5,1.5 0 0,0 14,12.5M10,17A1,1 0 0,0 9,18A1,1 0 0,0 10,19A1,1 0 0,0 11,18A1,1 0 0,0 10,17M10,8.5A1.5,1.5 0 0,0 8.5,10A1.5,1.5 0 0,0 10,11.5A1.5,1.5 0 0,0 11.5,10A1.5,1.5 0 0,0 10,8.5M14,20.5A0.5,0.5 0 0,0 13.5,21A0.5,0.5 0 0,0 14,21.5A0.5,0.5 0 0,0 14.5,21A0.5,0.5 0 0,0 14,20.5M14,17A1,1 0 0,0 13,18A1,1 0 0,0 14,19A1,1 0 0,0 15,18A1,1 0 0,0 14,17M21,13.5A0.5,0.5 0 0,0 20.5,14A0.5,0.5 0 0,0 21,14.5A0.5,0.5 0 0,0 21.5,14A0.5,0.5 0 0,0 21,13.5M18,5A1,1 0 0,0 17,6A1,1 0 0,0 18,7A1,1 0 0,0 19,6A1,1 0 0,0 18,5M18,9A1,1 0 0,0 17,10A1,1 0 0,0 18,11A1,1 0 0,0 19,10A1,1 0 0,0 18,9M18,17A1,1 0 0,0 17,18A1,1 0 0,0 18,19A1,1 0 0,0 19,18A1,1 0 0,0 18,17M18,13A1,1 0 0,0 17,14A1,1 0 0,0 18,15A1,1 0 0,0 19,14A1,1 0 0,0 18,13M10,12.5A1.5,1.5 0 0,0 8.5,14A1.5,1.5 0 0,0 10,15.5A1.5,1.5 0 0,0 11.5,14A1.5,1.5 0 0,0 10,12.5M10,7A1,1 0 0,0 11,6A1,1 0 0,0 10,5A1,1 0 0,0 9,6A1,1 0 0,0 10,7M10,3.5A0.5,0.5 0 0,0 10.5,3A0.5,0.5 0 0,0 10,2.5A0.5,0.5 0 0,0 9.5,3A0.5,0.5 0 0,0 10,3.5M10,20.5A0.5,0.5 0 0,0 9.5,21A0.5,0.5 0 0,0 10,21.5A0.5,0.5 0 0,0 10.5,21A0.5,0.5 0 0,0 10,20.5M3,13.5A0.5,0.5 0 0,0 2.5,14A0.5,0.5 0 0,0 3,14.5A0.5,0.5 0 0,0 3.5,14A0.5,0.5 0 0,0 3,13.5M14,3.5A0.5,0.5 0 0,0 14.5,3A0.5,0.5 0 0,0 14,2.5A0.5,0.5 0 0,0 13.5,3A0.5,0.5 0 0,0 14,3.5M14,7A1,1 0 0,0 15,6A1,1 0 0,0 14,5A1,1 0 0,0 13,6A1,1 0 0,0 14,7M21,10.5A0.5,0.5 0 0,0 21.5,10A0.5,0.5 0 0,0 21,9.5A0.5,0.5 0 0,0 20.5,10A0.5,0.5 0 0,0 21,10.5M6,5A1,1 0 0,0 5,6A1,1 0 0,0 6,7A1,1 0 0,0 7,6A1,1 0 0,0 6,5M3,9.5A0.5,0.5 0 0,0 2.5,10A0.5,0.5 0 0,0 3,10.5A0.5,0.5 0 0,0 3.5,10A0.5,0.5 0 0,0 3,9.5M6,9A1,1 0 0,0 5,10A1,1 0 0,0 6,11A1,1 0 0,0 7,10A1,1 0 0,0 6,9M6,17A1,1 0 0,0 5,18A1,1 0 0,0 6,19A1,1 0 0,0 7,18A1,1 0 0,0 6,17M6,13A1,1 0 0,0 5,14A1,1 0 0,0 6,15A1,1 0 0,0 7,14A1,1 0 0,0 6,13Z" />
									</svg>
									<div class="bg-tooltip">{{ $t('high_blur') }}</div>
								</div>
							</div>
							<div class="background-title">{{ $t('backgrounds') }}</div>
							<div class="opt-container">
								<div class="backgrounds-container-items" :class="{ open: showMediaPipe }">
									<div class="background-choice" :class="{ selected: background === bg }" @click="() => {
											background = bg
											setImageSrc()
										}
										" v-for="(bg, index) in backgrounds" :key="index" :style="'background:url(' + bg.image + ')'">
										<div class="bg-tooltip" v-text="bg.tooltip" />
									</div>
								</div>
							</div>
							<div class="background-title">{{ $t('aspectratio') }}</div>
							<div class="video-rateos">
								<div class="video-rateos-options">
									<button class="aspect" :class="{ selected: aspect === index }" @click="changeAspect(index)" v-for="(ratio, index) in ratios" :key="index">
										<span>{{ ratio }}</span>
									</button>
								</div>
							</div>
						</div>

						<div class="background-footer">
							<button @click="saveMediaPipe()">{{ $t('save_settings') }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="Controls">
			<div class="Controls-meet-title">
				<a href="https://cving.com" target="_blank"><img alt="CVing logo" src="/img/logo-cving.d1aad1ca.svg" height="30" width="85" /></a>
				<p>{{ meetName }}</p>
			</div>
			<div class="Controls-main-buttons">
				<span class="media-action-button-wrapper">
					<button class="media-action-button" @click="toggleAudio()" :class="{ 'media-action-button-disabled': !audioOn }">
						<svg viewBox="0 0 24 24">
							<path :d="audioOn
									? 'M12,2A3,3 0 0,1 15,5V11A3,3 0 0,1 12,14A3,3 0 0,1 9,11V5A3,3 0 0,1 12,2M19,11C19,14.53 16.39,17.44 13,17.93V21H11V17.93C7.61,17.44 5,14.53 5,11H7A5,5 0 0,0 12,16A5,5 0 0,0 17,11H19Z'
									: 'M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z'
								" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="audioOn ? $t('disable_audio') : $t('enable_audio')"></span>
				</span>
				<span class="media-action-button-wrapper">
					<button class="media-action-button" @click="toggleVideo()" :class="{ 'media-action-button-disabled': !videoOn }">
						<svg viewBox="0 0 24 24">
							<path :d="videoOn
									? 'M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z'
									: 'M3.27,2L2,3.27L4.73,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16C16.2,18 16.39,17.92 16.54,17.82L19.73,21L21,19.73M21,6.5L17,10.5V7A1,1 0 0,0 16,6H9.82L21,17.18V6.5Z'
								" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="videoOn ? $t('disable_video') : $t('enable_video')"></span>
				</span>
				<span class="media-action-button-wrapper" v-if="!isMobile">
					<button class="media-action-button" @click="shareScreen()" :class="{ 'media-action-button-disabled': !shareOn }">
						<svg viewBox="0 0 24 24" style="width: 24px; height: 24px">
							<path d="M23 4V16C23 17.11 22.11 18 21 18H15V16H21V4H3V16H9V18H3C1.9 18 1 17.11 1 16V4C1 2.89 1.89 2 3 2H21C22.1 2 23 2.89 23 4M13 13H16L12 9L8 13H11V20H8V22H16V20H13V13Z" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="!shareOn ? $t('share_screen') : $t('stop_share_screen')"></span>
				</span>
				<span class="media-action-button-wrapper" v-if="!isMobile">
					<button class="media-action-button" @click="recordVideo()" :class="recordOn ? 'record' : 'media-action-button-disabled'">
						<svg viewBox="0 0 24 24">
							<path :d="recordOn
									? 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z'
									: 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z'
								" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="!recordOn ? $t('register_screen') : $t('stop_register_screen')"></span>
					<div class="timestamp" v-if="timestampRecord">{{ getTime }}</div>
				</span>
				<span class="media-action-button-wrapper">
					<button class="hangup" @click="leave">
						<svg viewBox="0 0 24 24">
							<path fill="white" d="M12,9C10.4,9 8.85,9.25 7.4,9.72V12.82C7.4,13.22 7.17,13.56 6.84,13.72C5.86,14.21 4.97,14.84 4.17,15.57C4,15.75 3.75,15.86 3.5,15.86C3.2,15.86 2.95,15.74 2.77,15.56L0.29,13.08C0.11,12.9 0,12.65 0,12.38C0,12.1 0.11,11.85 0.29,11.67C3.34,8.77 7.46,7 12,7C16.54,7 20.66,8.77 23.71,11.67C23.89,11.85 24,12.1 24,12.38C24,12.65 23.89,12.9 23.71,13.08L21.23,15.56C21.05,15.74 20.8,15.86 20.5,15.86C20.25,15.86 20,15.75 19.82,15.57C19.03,14.84 18.14,14.21 17.16,13.72C16.83,13.56 16.6,13.22 16.6,12.82V9.72C15.15,9.25 13.6,9 12,9Z" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="$t('exit')"></span>
				</span>
			</div>
			<div class="Controls-info">
				<!-- <span class="chat-action" v-if="false">
					<button class="media-effects"  :class="{ mediapipeOn: mediapipeOn }" @click="manageRightBox('background')">
						<svg viewBox="0 0 24 24" style="width: 24px; height: 24px">
							<path
								fill="currentColor"
								d="M10.6 9.6L9 15L7.4 9.6L2 8L7.4 6.4L9 1L10.6 6.4L16 8L10.6 9.6M17 14.2L21 12L18.8 16L21 20L17 17.8L13 20L15.2 16L13 12L17 14.2M10 16L8.3 19L10 22L7 20.3L4 22L5.7 19L4 16L7 17.7L10 16"
							></path>
						</svg>
					</button>
					<span class="media-tooltip" v-text="'Impostazioni video'"></span>
				</span> -->
				<span class="chat-action list-users" @click="manageRightBox('list')">
					<span class="count-badge list-users-count" :class="{ active: newUser }">{{ videoList.filter((f) => !f.isScreenShare).length }}</span>
					<button :class="{ active: partecipantsVisible }">
						<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
							<path d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="!partecipantsVisible ? $t('open_list') : $t('close_list')"></span>
				</span>
				<span class="chat-action" @click="manageRightBox('chat')">
					<span class="count-badge message-chat-count" v-if="countMessage > 0">{{ countMessage }}</span>
					<button :class="{ active: chatVisible }" style="margin-top: 4px">
						<svg viewBox="0 0 24 24">
							<path d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18" />
						</svg>
					</button>
					<span class="media-tooltip" v-text="!chatVisible ? $t('open_chat') : $t('close_chat')"></span>
				</span>
				<span class="chat-action-mobile">
					<div class="menu-list" :class="{ show: chatActionMobile }">
						<!-- <div class="menu-list-option" @click="openMediaPipeFromMobile" :class="{ disabled: isEffectsDisabled }">
							<svg viewBox="0 0 24 24" style="width: 24px; height: 24px">
								<path
									fill="rgba(85,85,85,.8)"
									d="M10.6 9.6L9 15L7.4 9.6L2 8L7.4 6.4L9 1L10.6 6.4L16 8L10.6 9.6M17 14.2L21 12L18.8 16L21 20L17 17.8L13 20L15.2 16L13 12L17 14.2M10 16L8.3 19L10 22L7 20.3L4 22L5.7 19L4 16L7 17.7L10 16"
								></path>
							</svg>
							<span>{{ $t('video_settings') }}</span>
						</div> -->

						<div class="menu-list-option" @click="manageRightBox('chat')">
							<svg viewBox="0 0 24 24" style="width: 22px; height: 22px">
								<path fill="rgba(85,85,85,.8)" d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18" />
							</svg>
							<span>Chat </span>
						</div>
						<div class="menu-list-option" @click="manageRightBox('list')">
							<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
								<path fill="rgba(85,85,85,.8)" d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
							</svg>
							<span>{{ $t('partecipants') }} </span>
						</div>
					</div>
					<svg class="settings" style="width: 22px; height: 22px" viewBox="0 0 24 24" @click="chatActionMobile = !chatActionMobile">
						<path fill="white" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
					</svg>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import i18n from './../../i18n'
import { io } from 'socket.io-client'
import { EventBus } from '@/event-bus'
import { VueChatEmoji } from 'vue-chat-emoji'
import dayjs from 'dayjs'
import { SelfieSegmentation } from '@mediapipe/selfie_segmentation'
import { Camera } from '@mediapipe/camera_utils'
dayjs.locale(navigator.language || 'it')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SimpleSignalClient = require('simple-signal-client')

export default Vue.extend({
	name: 'vue-webrtc',
	components: { Emoji: VueChatEmoji },
	data() {
		return {
			temporaryAspect: null,
			showMediaPipe: false,
			background: null,
			blur: 0,
			backgrounds: [
				{ image: '/backgrounds/office1.jpg', tooltip: 'Contemporarary open space' },
				{ image: '/backgrounds/office2.jpg', tooltip: 'Cozy workplace at home' },
				{ image: '/backgrounds/office3.jpg', tooltip: 'Simple office' },
				{ image: '/backgrounds/office4.jpg', tooltip: 'Houseplants background' },
				{ image: '/backgrounds/office5.jpg', tooltip: 'Creative office' },
				{ image: '/backgrounds/office6.jpg', tooltip: 'Modern office' },
				{ image: '/backgrounds/office7.jpg', tooltip: 'Medical office' },
				{ image: '/backgrounds/office8.jpg', tooltip: 'Modern white office' },
				{ image: '/backgrounds/office9.jpg', tooltip: 'Office background' },
				{ image: '/backgrounds/office10.jpg', tooltip: 'Office interior' },
				{ image: '/backgrounds/office11.jpg', tooltip: 'Office with various plants' },
				{ image: '/backgrounds/office12.jpg', tooltip: 'Office room with a white background' }
			],
			mediapipeOn: false,
			chatActionMobile: false,
			showEmoji: false,
			meetName: '',
			localStream: null,
			signalClient: null,
			videoList: [],
			canvas: null,
			socket: null,
			// style
			margin: 10,
			ratios: ['4:3', '16:9', '1:1', '1:2'],
			aspect: 0,
			dishWidth: 0,
			dishHeight: 0,
			videoOn: false,
			audioOn: false,
			shareOn: false,
			screenStream: MediaStream,
			partecipant: null, // it's me
			chatVisible: false,
			messages: [],
			textMessage: '',
			countMessage: 0,
			newUser: false,
			partecipantsVisible: false,
			alreadyOpen: false,
			recordOn: false,
			recordedScreen: MediaStream,
			mediaRecorder: null,
			timestampRecord: null,
			dayjs,
			interval: null,
			showPartecipants: false,
			image: null,
			selfieStart: false,
			selfieSegementationsCostructors: [],
			selfieSegementationsImages: [],
			cameras: [],
			showToast: false,
			alertTimeout: null,
			tmpVideoList: []
		}
	},
	props: {
		roomId: {
			type: String,
			default: 'public-room-v2'
		},
		socketURL: {
			type: String,
			// default: 'https://vue-webrtc-lobby.gcp.cving.com/'
			default: 'http://localhost:4001'
		},
		cameraHeight: {
			type: [Number, String],
			default: 160
		},
		autoplay: {
			type: Boolean,
			default: true
		},
		screenshotFormat: {
			type: String,
			default: 'image/jpeg'
		},
		enableLogs: {
			type: Boolean,
			default: false
		},
		peerOptions: {
			type: Object, // NOTE: use these options: https://github.com/feross/simple-peer
			default() {
				return {
					config: {
						iceServers: [{
							urls: ['stun:fr-turn1.xirsys.com']
						}, {
							username: 'k2D5P9ZY7gpG9NEkQbBq3KkPqcQqavRZQ2G4hWRURw0dtPIDgg03davhhigxyW3tAAAAAGMYUQl2dmFsbG9yYW5p',
							credential: 'fcd697da-2e83-11ed-a089-0242ac120004',
							urls: [
								'turn:fr-turn1.xirsys.com:80?transport=udp',
								'turn:fr-turn1.xirsys.com:3478?transport=udp',
								'turn:fr-turn1.xirsys.com:80?transport=tcp',
								'turn:fr-turn1.xirsys.com:3478?transport=tcp',
								'turns:fr-turn1.xirsys.com:443?transport=tcp',
								'turns:fr-turn1.xirsys.com:5349?transport=tcp'
							]
						}]
					}
				}
			}
		},
		ioOptions: {
			type: Object, // NOTE: use these options: https://socket.io/docs/v4/client-options/
			default() {
				return {
					rejectUnauthorized: false,
					transports: ['polling', 'websocket']
				}
			}
		},
		deviceId: {
			type: String,
			default: null
		}
	},
	watch: {
		chatVisible() {
			if (this.chatVisible) {
				this.countMessage = 0
			}
		},
		videoList: {
			// Will fire as soon as the component is created
			immediate: true,
			deep: true,
			handler(videoList) {
				if (videoList.length > 0 && videoList.find((v) => v.isRecording && !v.isLocal)) {
					this.showToast = true
					this.dimensions()
					this.alertTimeout = setTimeout(() => {
						this.dimensions()
						this.showToast = false
					}, 5000)
				} else this.dimensions()
			}
		}
	},
	computed: {
		isEffectsDisabled() {
			const local = this.videoList.find((f) => f.isLocal)
			if (local) {
				return !local.videoEnabled
			}
			return false
		},
		getPartecipants() {
			const string = this.textMessage.slice(1)
			let filtred = JSON.parse(JSON.stringify(this.videoList))
			if (string !== '') {
				filtred = filtred.filter((f) => !f.isLocal && f.name.toLowerCase().replace(' ', '_').startsWith(string.toLowerCase()))
			} else {
				filtred = filtred.filter((f) => !f.isLocal)
			}
			if (filtred) {
				filtred.forEach((p) => {
					p.name = p.name.replace(' ', '_')
				})
				return filtred
			}
			return []
		},
		getTop() {
			if (this.getPartecipants) {
				let calc = this.getPartecipants.length * 25
				calc = calc + 28
				return calc >= 135 ? 135 : calc
			}
			return 0
		},
		getTime() {
			return this.timestampRecord.format('HH:mm:ss')
		},
		getRecordingName() {
			let name = ''
			for (let i = this.videoList.length - 1; i >= 0; i--) {
				const v = this.videoList[i]
				if (v.isRecording && !v.isLocal) {
					name = v.name.charAt(0).toUpperCase() + v.name.slice(1) + ' '
					break
				}
			}
			return name
		},
		ratio() {
			if (this.aspect !== null) {
				const ratio = this.ratios[this.aspect].split(':')
				return ratio[1] / ratio[0]
			}
			return 4 / 3
		},
		getVideoStyle() {
			const m = this.margin + 'px'
			const w = this.dishWidth + 'px'
			const h = this.dishWidth * this.ratio + 'px'
			return {
				width: w,
				margin: m,
				height: h
			}
		},
		videoPinned() {
			return this.videoList.find((f) => f.pinned)
		},
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		}
	},
	methods: {
		/**
		 * extract a parameter value from the page url using a given parameter `name`
		 * @param name the parameter name to search for
		 * @returns the value of the parameter
		 */
		getUrlParameter(name) {
			// name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
			const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
			const results = regex.exec(location.search)
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
		},
		getName(item) {
			if (item.name) {
				let name = item.name.split(' ')
				name = name[0].substring(0, 1).toUpperCase() + name[1].substring(0, 1).toUpperCase()
				return name
			} else {
				return ''
			}
		},
		openMediaPipeFromMobile() {
			if (!this.isEffectsDisabled) {
				this.manageRightBox('background')
			}
		},
		moveUlFocus(e) {
			if ((e.keyCode === 38 || e.keyCode === 40) && this.showPartecipants) {
				const ul = document.getElementById('ul-list-messages')
				let index = null
				for (let i = 0; i < ul.children.length; i++) {
					const c = ul.children[i]
					if (c === document.activeElement) {
						index = i
						break
					}
				}
				if (index === null) {
					index = e.keyCode === 38 ? 1 : ul.children.length
				}
				if (e.keyCode === 38) {
					if (index > 0) {
						ul.children[index - 1].focus()
					} else {
						ul.children[ul.childElementCount - 1].focus()
					}
				} else {
					if (index >= ul.childElementCount - 1) {
						ul.children[0].focus()
					} else {
						ul.children[index + 1].focus()
					}
				}
			}
		},
		showSuggestion(e) {
			if (e.type === 'click') {
				this.showPartecipants = true
			}
			this.showEmoji = false
			const ul = document.getElementById('ul-list-messages')
			let li = null
			if (ul) {
				li = e.keyCode === 38 || e.type === 'click' ? ul.firstChild : ul.lastChild
			}
			if (li) {
				if (e.type === 'click') {
					ul.parentNode.style.left = '-33px'
					li.focus()
				} else {
					if ((e.keyCode === 38 || e.keyCode === 40) && this.showPartecipants) {
						li.focus()
					}
					if (e.target.selectionStart === 1) {
						this.showPartecipants = true
					}
					if (this.textMessage.charAt(0) === '@') {
						const split = this.textMessage.split(' ')
						if (split.length === 1) {
							const string = this.textMessage.slice(1)
							const found = this.videoList.find((f) => !f.isLocal && f.name.toLowerCase().startsWith(string.toLowerCase()))
							if (found.name === string) {
								this.showPartecipants = false
							} else {
								found ? (this.showPartecipants = true) : (this.showPartecipants = false)
							}
						} else {
							this.showPartecipants = false
						}
					} else {
						this.showPartecipants = false
					}
				}
			}
		},
		setSuggestion(name) {
			this.textMessage = '@' + name.replace(' ', '_') + ' '
			this.showPartecipants = false
			const input = document.getElementById('field-chat-messages')
			input.focus()
		},
		startLocal() {
			const newVideo = document.createElement('video')
			newVideo.srcObject = this.localStream
			newVideo.autoplay = true
			newVideo.muted = true
			newVideo.controls = false

			let canvasElement = document.getElementsByClassName('output_canvas_preview')[0]
			while (!canvasElement) {
				canvasElement = document.getElementsByClassName('output_canvas_preview')[0]
			}
			const canvasCtx = canvasElement.getContext('2d')

			let selfieSegmentation = new SelfieSegmentation({
				locateFile: (file) => {
					return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
				}
			})
			selfieSegmentation.setOptions({
				modelSelection: 1,
				selfieMode: true
			})
			selfieSegmentation.onResults((results) => {
				this.onResults(canvasCtx, canvasElement, results)
			})

			this.cameraMediaPipe = new Camera(newVideo, {
				onFrame: async () => {
					if (this.selfieStart && selfieSegmentation) {
						await selfieSegmentation.initialize()
						await selfieSegmentation.send({ image: newVideo })
					} else {
						if (selfieSegmentation) {
							selfieSegmentation.close()
							selfieSegmentation = null
						}
					}
				},
				width: 1280,
				height: 720
			})
			this.cameraMediaPipe.start()
		},
		onResults(canvasCtx, canvasElement, results) {
			canvasCtx.save()
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height)
			canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height)

			// Only overwrite existing pixels.
			canvasCtx.globalCompositeOperation = 'source-in'
			canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height)

			// Only overwrite missing pixels.
			canvasCtx.globalCompositeOperation = 'destination-atop'
			canvasCtx.filter = 'blur(' + this.blur + 'px)'
			// canvasCtx.filter = 'hue-rotate(90deg)'

			if (this.image) {
				canvasCtx.drawImage(this.image, 0, 0, canvasElement.width, canvasElement.height)
			} else {
				canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height)
			}
			canvasCtx.restore()
		},
		setImageSrc() {
			this.image = null
			let i = null
			i = document.createElement('img')
			if (this.background && this.background.image) {
				i.src = this.background.image
				this.image = i
			}
		},
		closeModal() {
			if ((!this.background || this.background.image === '') && (!this.blur || this.blur <= 0)) {
				this.selfieStart = false
			}
			this.showMediaPipe = false
		},
		saveMediaPipe() {
			const video = this.videoList.find((v) => v.isLocal)
			if ((this.background && this.background.image !== '') || this.blur > 0) {
				this.mediapipeOn = true
				this.socket.emit('manageMediapipe', {
					key: this.roomId,
					id: video.id,
					videoEffects: {
						blur: this.blur,
						src: this.background?.image || ''
					}
				})
			} else {
				this.mediapipeOn = false
				this.socket.emit('manageMediapipe', {
					key: this.roomId,
					id: this.localStream.id,
					videoEffects: {
						blur: 0,
						src: ''
					}
				})
			}
			this.showMediaPipe = false
			this.selfieStart = false
		},
		startMediaPipe(video) {
			/* 		if (this.selfieSegementationsCostructors[video.id]) {
				this.selfieSegementationsCostructors[video.id].close()
				delete this.selfieSegementationsCostructors[video.id]
				delete this.selfieSegementationsImages[video.id]
				delete this.cameras[video.id]
			} */
			const videoElement = document.getElementById(video.id) ? document.getElementById(video.id) : document.getElementById('pinned_' + video.id)
			if (!videoElement) return

			videoElement.width = videoElement.parentNode.offsetWidth
			videoElement.height = videoElement.parentNode.offsetHeight
			const canvasElement = document.getElementsByClassName('output_canvas_' + video.id)[0]
			const canvasCtx = canvasElement.getContext('2d')
			let i = null
			i = document.createElement('img')
			i.width = videoElement.width
			i.height = videoElement.height
			i.src = video.videoEffects.src

			this.selfieSegementationsImages[video.id] = i
			if (!this.selfieSegementationsCostructors[video.id]) {
				this.selfieSegementationsCostructors[video.id] = new SelfieSegmentation({
					locateFile: (file) => {
						return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`
					}
				})
			}
			this.selfieSegementationsCostructors[video.id].setOptions({
				modelSelection: 1,
				selfieMode: true
			})

			this.selfieSegementationsCostructors[video.id].onResults((results) => {
				canvasCtx.save()
				canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height)
				canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height)

				// Only overwrite existing pixels.
				canvasCtx.globalCompositeOperation = 'source-in'
				canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height)

				// Only overwrite missing pixels.
				canvasCtx.globalCompositeOperation = 'destination-atop'
				canvasCtx.filter = 'blur(' + video.videoEffects.blur + 'px)'

				if (video.videoEffects.src) {
					canvasCtx.drawImage(i, 0, 0, canvasElement.width, canvasElement.height)
				} else {
					canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height)
				}

				canvasCtx.restore()
			})

			const sendToMediaPipe = async () => {
				if (!videoElement) {
					requestAnimationFrame(sendToMediaPipe)
				} else {
					await this.selfieSegementationsCostructors[video.id]
						.send({ image: videoElement })
						.then(() => {
							requestAnimationFrame(sendToMediaPipe)
						})
						.catch((err) => {
							console.error(err)
						})
				}
			}
			sendToMediaPipe()
		},
		toggleTimer() {
			this.interval = setInterval(() => {
				if (this.timestampRecord) {
					this.timestampRecord = this.timestampRecord.add(1, 'second')
				}
			}, 1000)
		},
		getBubbleMinWidth(text) {
			const canvas = this.getBubbleMinWidth.canvas || (this.getBubbleMinWidth.canvas = document.createElement('canvas'))
			const context = canvas.getContext('2d')
			const metrics = context.measureText(text)
			if (metrics.width > 70) {
				return 'min-width:' + metrics.width + 'px!important'
			}
		},
		addEmoji(emoji) {
			this.textMessage += ' ' + emoji.emoji + ' '
		},
		manageRightBox(element) {
			this.chatActionMobile = false
			this.showEmoji = false
			if (element === 'chat') {
				if (this.partecipantsVisible) {
					this.alreadyOpen = true
				}
				this.showMediaPipe = false
				this.partecipantsVisible = false
				this.chatVisible = !this.chatVisible
				const myTimeout = setTimeout(() => {
					const element = document.getElementById('chat-messages')
					if (element) {
						element.scrollTop = element.scrollHeight
					}
					this.dimensions()
					clearTimeout(myTimeout)
				}, 405)
			}

			if (element === 'list') {
				this.showMediaPipe = false
				this.chatVisible = false
				this.partecipantsVisible = !this.partecipantsVisible
				this.dimensions()
				const myTimeout = setTimeout(() => {
					this.dimensions()
					clearTimeout(myTimeout)
				}, 405)
			}
			if (element === 'background') {
				this.chatVisible = false
				this.partecipantsVisible = false
				this.showMediaPipe = !this.showMediaPipe
				if (this.showMediaPipe) {
					this.selfieStart = true
					this.startLocal()
				} else {
					if (Number(this.temporaryAspect) >= 0) {
						this.aspect = this.temporaryAspect
						this.temporaryAspect = this.aspect
					}
				}
			}

			if (this.chatVisible || this.partecipantsVisible || this.showMediaPipe) {
				this.margin = 20
			} else {
				this.margin = 10
			}
			this.dimensions()
		},
		addMessage() {
			this.showEmoji = false
			if (this.textMessage === '') {
				return
			}
			if (this.textMessage.charAt(0) === '@') {
				const split = this.textMessage.trim().split(' ')
				if (split.length === 1) {
					return
				}
			}
			const date = new Date()
			let hours = date.getHours()
			hours = hours >= 10 ? hours : '0' + hours
			let minutes = date.getMinutes()
			minutes = minutes >= 10 ? minutes : '0' + minutes
			const request = {
				id: this.videoList.find((f) => f.isLocal).id,
				name: this.partecipant.partecipantName,
				text: this.textMessage,
				time: hours + ':' + minutes,
				key: this.roomId
			}
			if (this.textMessage.charAt(0) === '@') {
				request.receiver = this.videoList.find((f) => this.textMessage.toUpperCase().includes(f.name.toUpperCase().replace(' ', '_'))).id
				const replacedText = this.textMessage.split(' ').slice(1).join(' ')
				request.text = replacedText
				this.socket.emit('addPrivateMessage', request)
			} else {
				this.socket.emit('addMessage', request)
			}
		},
		changeAspect(index) {
			this.temporaryAspect = this.aspect
			this.aspect = index

			this.dimensions()
		},

		async toggleVideo() {
			const video = this.localStream.getTracks().find((track) => track.kind === 'video')

			if (video && video.readyState === 'ended') {
				const newStream = await navigator.mediaDevices.getUserMedia({
					video: true,
					audio: this.audioOn
				})
				this.signalClient.peers().forEach((p) => {
					const old = this.localStream.getVideoTracks()[0]
					old.enabled = false
					p.removeTrack(old, this.localStream)
					p.addTrack(newStream.getVideoTracks()[0], this.localStream)
					// p.replaceTrack(this.localStream.getVideoTracks()[0], newStream.getVideoTracks()[0], this.localStream)
				})
				this.localStream.removeTrack(video)
				this.localStream.addTrack(newStream.getVideoTracks()[0])
				this.toggleVideo()

				return
			}

			if (!this.videoOn) {
				video.enabled = true
				this.socket.emit('enableVideo', {
					key: this.roomId,
					id: this.localStream.id
				})
			} else {
				video.enabled = false
				this.socket.emit('disableVideo', {
					key: this.roomId,
					id: this.localStream.id
				})
				this.localStream.getVideoTracks().forEach((track) => track.stop())
			}
			this.videoOn = !this.videoOn
		},
		toggleAudio() {
			const audio = this.localStream.getTracks().find((track) => track.kind === 'audio')
			if (this.audioOn) {
				audio.enabled = false
				this.socket.emit('disableAudio', {
					key: this.roomId,
					id: this.localStream.id
				})
			} else {
				audio.enabled = true
				this.socket.emit('enableAudio', {
					key: this.roomId,
					id: this.localStream.id
				})
			}
			this.audioOn = !this.audioOn
		},

		frameLoop(id, analyser) {
			window.requestAnimationFrame(() => this.frameLoop(id, analyser))
			const borderEl = document.getElementsByClassName('video-' + id)[0]
			const circleEl = document.getElementsByClassName('initials-audio-effect-' + id)[0]
			const circleElBig = document.getElementsByClassName('initials-audio-effect-big-' + id)[0]
			const e1 = document.getElementsByClassName('o1_' + id)[0]
			const e2 = document.getElementsByClassName('o2_' + id)[0]
			const e3 = document.getElementsByClassName('o3_' + id)[0]

			const e4 = document.getElementsByClassName('o4_' + id)[0]
			const e5 = document.getElementsByClassName('o5_' + id)[0]
			const e6 = document.getElementsByClassName('o6_' + id)[0]

			const fbc_array = new Uint8Array(analyser.frequencyBinCount)

			analyser.getByteFrequencyData(fbc_array)

			let o1 = fbc_array[20] / 300
			let o2 = fbc_array[50] / 200

			o1 = o1 < 0.5 ? 0.19 : o1 > 1 ? 1 : o1
			o2 = o2 < 0.4 ? 0.19 : o2 > 1 ? 1 : o2

			const scale2 = o2 * 5 > 2 ? 2 : o2 * 4.5
			const scale1 = o1 * 5 > 2 ? 2 : o1 * 5

			if (circleElBig) {
				circleElBig.style.transform = 'scale(' + scale2 + ')'
				circleElBig.style.opacity = scale2 > 1 ? 0 : scale2 / 2
			}
			if (circleEl) {
				circleEl.style.transform = 'scale(' + scale1 + ')'
				circleEl.style.opacity = scale1 * 5
			}

			if (borderEl) {
				const px = o2 * 5.5
				borderEl.style.border = px + 'px solid hsla(210, 65%, 67%,' + o2 * 2 + ')'
			}
			if (e1) {
				e1.style.height = `${o1 * 100}%`
			}
			if (e3) {
				e3.style.height = `${o1 * 100}%`
			}
			if (e2) {
				e2.style.height = `${o2 * 100}%`
			}
			if (e4) {
				e4.style.height = `${o1 * 100}%`
			}
			if (e5) {
				e5.style.height = `${o1 * 100}%`
			}
			if (e6) {
				e6.style.height = `${o2 * 100}%`
			}
		},

		dimensions() {
			const dish = document.getElementsByClassName('Dish')[0]
			if (dish) {
				const dimension = {
					width: dish.offsetWidth - this.margin * 2,
					height: dish.offsetHeight - this.margin * 2
				}
				// loop (i recommend you optimize this)
				let max = 0
				for (let i = 0; i < 5000; i++) {
					const area = this.area(i, dimension)
					if (area === false) {
						max = i - 1
						break
					}
				}

				// remove margins
				max = max - this.margin * 2

				this.dishWidth = max
				this.dishHeight = dimension.height
			}
		},
		area(increment, dimension) {
			let w = 0
			let h = increment * this.ratio + this.margin * 2

			for (let i = 0; i < this.videoList.length; i++) {
				if (w + increment > dimension.width) {
					w = 0
					h = h + increment * this.ratio + this.margin * 2
				}
				w = w + increment + this.margin * 2
			}

			if (h > dimension.height || increment > dimension.width) return false
			return increment
		},

		expand(video) {
			const someVideoAlreadyPinned = this.videoList.find((f) => f.pinned)
			if (someVideoAlreadyPinned) this.unexpand(someVideoAlreadyPinned)

			this.videoList.forEach((v) => {
				if (v.id === video.id) {
					Vue.set(v, 'pinned', true)
					setTimeout(() => {
						const element = document.getElementById(v.id)
						if (element) {
							element.srcObject = v.stream
							this.dimensions()
						}
					}, 500)
				}
			})
		},

		unexpand(video) {
			this.videoList.forEach((v) => {
				if (v.id === video.id) {
					Vue.set(v, 'pinned', false)
					setTimeout(() => {
						document.getElementById(v.id).srcObject = v.stream
						this.dimensions()
					}, 500)
				}
			})
		},

		async join(partecipant) {
			this.meetName = partecipant.meetName
			this.partecipant = partecipant
			EventBus.$emit('meetJoined')

			this.signalClient = new SimpleSignalClient(this.socket)
			const constraints = {
				video: true, // partecipant.enableVideo
				audio: true
			}
			this.videoOn = partecipant.enableVideo
			this.audioOn = partecipant.enableAudio
			this.mediapipeOn = (partecipant.enableVideo && partecipant.blur > 0) || partecipant.src !== ''
			if (this.deviceId && partecipant.enableVideo) {
				constraints.video = { deviceId: { exact: this.deviceId } }
			}
			this.localStream = await navigator.mediaDevices.getUserMedia(constraints)

			if (!partecipant.enableVideo) this.localStream.getVideoTracks().forEach((track) => track.stop())

			await this.signalClient.discover(this.roomId)

			this.joinedRoom(this.localStream, true)

			this.signalClient.once('discover', (discoveryData) => {
				const connectToPeer = async (peerID) => {
					if (peerID === this.socket.id) return // it's me
					try {
						const test = await this.signalClient.connect(peerID, this.roomId, this.peerOptions)
						this.videoList.forEach((v) => {
							if (v.isLocal) {
								this.onPeer(test.peer, v.stream)
							}
						})
					} catch (e) {
						console.error('Error connecting to peer', e)
					}
				}

				discoveryData.peers.forEach((peerID) => connectToPeer(peerID))
			})
			this.signalClient.on('request', async (request) => {
				const { peer } = await request.accept({}, this.peerOptions)
				this.videoList.forEach((v) => {
					if (v.isLocal) {
						this.onPeer(peer, v.stream)
						// this.joinedRoom(this.localStream, true)
					}
				})
			})

			this.socket.on('disableVideo', (id) => (this.videoList.find((f) => f.id === id).videoEnabled = false))
			this.socket.on('enableVideo', (id) => (this.videoList.find((f) => f.id === id).videoEnabled = true))
			this.socket.on('disableAudio', (id) => (this.videoList.find((f) => f.id === id).audioEnabled = false))
			this.socket.on('enableAudio', (id) => (this.videoList.find((f) => f.id === id).audioEnabled = true))
			this.socket.on('startRecord', (id) => {
				this.videoList.find((f) => f.id === id).isRecording = true
				this.dimensions()
			})
			this.socket.on('stopRecord', (id) => {
				this.videoList.find((f) => f.id === id).isRecording = false
				const x = this.videoList.find((f) => f.isRecording)
				if (!x) {
					clearTimeout(this.alertTimeout)
				}
				this.dimensions()
			})
			this.socket.on('manageMediapipe', (obj) => {
				const video = this.videoList.find((f) => f.id === obj.id)
				video.videoEffects = obj.videoEffects

				if (video.videoEffects.blur > 0 || video.videoEffects.src !== '') {
					this.startMediaPipe(video)
				}
			})
			this.socket.on('stopScreenShare', (obj) => {
				const video = this.videoList.find((f) => f.id === obj)
				const index = this.videoList.indexOf(video)
				if (index !== -1) {
					this.videoList.splice(index, 1)
					setTimeout(() => {
						this.dimensions()
					}, 400)
				}
			})
		},
		onPeer(peer, localStream) {
			this.log('onPeer')
			peer.addStream(localStream)
			peer.on('stream', (remoteStream) => {
				this.joinedRoom(remoteStream, false)
				peer.on('close', () => {
					var newList = []
					this.videoList.forEach((item) => {
						if (item.id !== remoteStream.id) {
							newList.push(item)
						}
					})
					this.videoList = newList
				})
				peer.on('error', (err) => {
					this.log('peer error ', err)
				})
			})
		},
		joinedRoom(stream, isLocal, isScreenShare) {
			const found = this.videoList.find((v) => v.id === stream.id)
			if (found === undefined || !found.stream) {
				const video = {
					id: stream.id, // stream of the current user
					muted: isLocal,
					stream,
					isLocal,
					videoEnabled: isScreenShare || this.partecipant.enableVideo,
					audioEnabled: isScreenShare ? false : this.partecipant.enableAudio,
					isScreenShare,
					isRecording: false,
					videoEffects: isScreenShare ? { blur: 0, src: '' } : { blur: this.partecipant.blur, src: this.partecipant.src }
				}
				const partecipant = this.tmpVideoList.find((f) => f.id === video.id)
				if (partecipant) {
					video.avatarColor = partecipant.avatarColor
					video.videoEnabled = partecipant.videoEnabled
					video.audioEnabled = partecipant.audioEnabled
					video.name = partecipant.name
					video.isScreenShare = partecipant.isScreenShare
					video.isRecording = partecipant.isRecording

					if (partecipant.videoEffects && !partecipant.isScreenShare) {
						video.videoEffects = {
							blur: partecipant.videoEffects.blur,
							src: partecipant.videoEffects.src
						}
					} else {
						video.videoEffects = {
							blur: 0,
							src: ''
						}
					}
				}
				this.dimensions()

				this.videoList.push(video)

				this.socket.emit('joined', {
					key: this.roomId,
					id: stream.id,
					name: this.partecipant.partecipantName,
					videoEnabled: isScreenShare || this.partecipant.enableVideo,
					audioEnabled: isScreenShare ? false : this.partecipant.enableAudio,
					isScreenShare,
					isRecording: false,
					videoEffects: isScreenShare ? {} : { blur: this.partecipant.blur, src: this.partecipant.src }
				})
			}

			setTimeout(() => {
				// if its me and i'm muted emit socket
				if (!this.partecipant.enableAudio && isLocal) {
					this.socket.emit('disableAudio', {
						key: this.roomId,
						id: this.localStream.id
					})

					this.localStream.getTracks().find((track) => track.kind === 'audio').enabled = false
					this.audioOn = false
				}
				// if its me and i'm using mediapipe

				if (!isScreenShare) {
					const audioCTX = new AudioContext()
					const analyser = audioCTX.createAnalyser()
					const source = audioCTX.createMediaStreamSource(stream)
					source.connect(analyser)
					this.frameLoop(stream.id, analyser)
				}
			}, 500)

			// animation for people icon
			if (!isLocal) {
				this.newUser = true
				setTimeout(() => {
					this.newUser = false
				}, 2000)
			}
		},
		leave() {
			this.videoList.forEach((v) => v.stream.getTracks().forEach((t) => t.stop()))
			this.videoList = []

			this.signalClient.peers().forEach((peer) => peer.removeAllListeners())
			this.signalClient.destroy()
			this.signalClient = null
			this.socket.destroy()
			this.socket = null

			EventBus.$emit('left-room')

			this.socket = io(this.socketURL, this.ioOptions)
			this.signalClient = new SimpleSignalClient(this.socket)
		},
		capture() {
			return this.getCanvas().toDataURL(this.screenshotFormat)
		},
		getCanvas() {
			const video = this.$refs.videos[0]
			if (video !== null && !this.ctx) {
				const canvas = document.createElement('canvas')
				canvas.height = video.clientHeight
				canvas.width = video.clientWidth
				this.canvas = canvas
				this.ctx = canvas.getContext('2d')
			}
			const { ctx, canvas } = this
			ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
			return canvas
		},
		async recordVideo() {
			console.log('recordVideo', this.mediaRecorder)
			if (this.mediaRecorder) {
				this.socket.emit('stopRecord', { key: this.roomId, id: this.localStream.id })
				this.interval = null
				this.mediaRecorder.stop()
				this.recordOn = false
				this.recordedScreen.getTracks().forEach((track) => track.stop())
				this.recordedScreen = null
				this.mediaRecorder = null
				this.timestampRecord = null
			} else {
				try {
					this.recordedScreen = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false, preferCurrentTab: true })
					this.recordedScreen.getVideoTracks()[0].onended = () => {
						// serve per stoppare la registrazione
						this.recordVideo()
					}

					let micNumber = 0
					await navigator.mediaDevices
						.enumerateDevices()
						.then((devices) => {
							devices.forEach(function (device) {
								if (device.kind === 'audioinput') {
									micNumber++
								}
							})

							if (micNumber === 0) {
								//	no audio inputs present on device/pc
								this.onCombinedStreamAvailable(this.recordedScreen)
							} else {
								//	audio inputs present, let's get access
								navigator.mediaDevices
									.getUserMedia({ audio: true })
									.then(async (micStream) => {
										//	create a new stream in which to pack everything together
										const composedStream = new MediaStream()

										//	add the screen video stream
										this.recordedScreen.getVideoTracks().forEach(function (videoTrack) {
											composedStream.addTrack(videoTrack)
										})

										//	if system audio has been shared
										if (this.recordedScreen.getAudioTracks().length > 0) {
											//	merge the system audio with the mic audio
											const context = new AudioContext()
											const audioDestination = context.createMediaStreamDestination()

											const systemSource = context.createMediaStreamSource(this.recordedScreen)
											const systemGain = context.createGain()
											systemGain.gain.value = 1.0
											systemSource.connect(systemGain).connect(audioDestination)
											if (micStream && micStream.getAudioTracks().length > 0) {
												const micSource = context.createMediaStreamSource(micStream)
												const micGain = context.createGain()
												micGain.gain.value = 1.0
												micSource.connect(micGain).connect(audioDestination)
											}

											audioDestination.stream.getAudioTracks().forEach(function (audioTrack) {
												composedStream.addTrack(audioTrack)
											})
										} else {
											//	add just the mic audio
											micStream.getAudioTracks().forEach(function (micTrack) {
												composedStream.addTrack(micTrack)
											})
										}
										await this.onCombinedStreamAvailable(composedStream)
										const chunks = []
										let options = {}
										if (typeof MediaRecorder.isTypeSupported === 'function') {
											if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
												options = { mimeType: 'video/webm;codecs=vp9' }
											} else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
												options = { mimeType: 'video/webm;codecs=h264' }
											} else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
												options = { mimeType: 'video/webm;codecs=vp8' }
											}
											this.mediaRecorder = new MediaRecorder(this.recordedScreen, options)
										} else {
											this.mediaRecorder = new MediaRecorder(this.recordedScreen)
										}

										this.mediaRecorder.ondataavailable = (e) => {
											chunks.push(e.data)
										}

										this.mediaRecorder.onerror = (e) => {
											console.error('mediaRecorder.onerror: ' + e)
										}

										/* this.mediaRecorder.onstart = () => {
											this.recordedScreen.getTracks().forEach(function (track) {
												if (track.kind === 'audio') {
													console.log('onstart - Audio track.readyState=' + track.readyState + ', track.muted=' + track.muted)
												}
												if (track.kind === 'video') {
													console.log('onstart - Video track.readyState=' + track.readyState + ', track.muted=' + track.muted)
												}
											})
										} */

										this.mediaRecorder.onstop = () => {
											const blob = new Blob(chunks, { type: 'video/webm' })

											const videoURL = window.URL.createObjectURL(blob)
											const link = document.createElement('a')
											let today = new Date()
											const dd = String(today.getDate()).padStart(2, '0')
											const mm = String(today.getMonth() + 1).padStart(2, '0')
											const yyyy = today.getFullYear()

											today = mm + '-' + dd + '-' + yyyy
											const name = this.meetName.replace(' ', '_') + '_' + today + '.webm'

											link.setAttribute('download', name)
											link.setAttribute('name', name)
											link.href = videoURL
											document.body.appendChild(link)
											link.click()

											/* 		downloadLink.href = videoURL
				videoElement.src = videoURL
				downloadLink.innerHTML = 'Download video file'

				var rand = Math.floor(Math.random() * 10000000)
				var name = 'video_' + rand + '.webm'

				downloadLink.setAttribute('download', name)
				downloadLink.setAttribute('name', name) */
										}

										this.mediaRecorder.onwarning = (e) => {
											console.warn('mediaRecorder.onwarning: ' + e)
										}

										this.mediaRecorder.start(10)
										this.recordOn = true

										this.recordedScreen.getTracks().forEach(function (track) {
											console.log(track.kind + ':' + JSON.stringify(track.getSettings()))
											console.log(track.getSettings())
										})
									})
									.catch((err) => {
										console.error('navigator.getUserMedia error: ' + err)
										this.recordOn = false
									})
							}
						})
						.catch(function (err) {
							console.error(err)
						})
				} catch (e) {
					console.error(e)
				}
			}
		},
		async shareScreen() {
			console.log('shareScreen')
			if (this.shareOn) {
				this.shareOn = false
				this.screenStream.getTracks().forEach((track) => track.stop())
				this.socket.emit('stopScreenShare', {
					key: this.roomId,
					id: this.screenStream.id
				})
				return
			}

			if (navigator.mediaDevices === undefined) {
				this.log('Error: https is required to load cameras')
				return
			}

			try {
				this.screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false })
				this.joinedRoom(this.screenStream, true, true)
				this.signalClient.peers().forEach((p) => this.onPeer(p, this.screenStream))
				this.shareOn = true

				this.screenStream.getVideoTracks()[0].onended = () => {
					this.socket.emit('stopScreenShare', {
						key: this.roomId,
						id: this.screenStream.id
					})
				}
			} catch (e) {
				this.log('Media error: ' + JSON.stringify(e))
			}
		},
		onCombinedStreamAvailable(stream) {
			this.recordedScreen = null
			this.recordedScreen = stream
			this.timestampRecord = dayjs('1900-01-01 00:00:00')
			this.toggleTimer()
			this.socket.emit('startRecord', { key: this.roomId, id: this.localStream.id })
		},
		log(message, data) {
			if (this.enableLogs) {
				console.log(message)
				if (data != null) {
					console.log(data)
				}
			}
		}
	},
	async mounted() {
		i18n.locale = this.getUrlParameter('lang') || 'it'

		window.addEventListener('resize', this.dimensions)
		this.socket = io(this.socketURL, this.ioOptions)
		this.socket.on('joined', ({ partecipants }) => {
			this.tmpVideoList = partecipants
			setTimeout(() => {
				var newList = []
				this.videoList.forEach((item) => {
					const partecipant = partecipants.find((p) => p.id === item.id)

					if (partecipant) {
						item.avatarColor = partecipant.avatarColor
						item.videoEnabled = partecipant.videoEnabled
						item.audioEnabled = partecipant.audioEnabled
						item.name = partecipant.name
						item.isScreenShare = partecipant.isScreenShare
						item.isRecording = partecipant.isRecording

						if (partecipant.videoEffects) {
							item.videoEffects = {
								blur: partecipant.videoEffects.blur,
								src: partecipant.videoEffects.src
							}
						} else {
							item.videoEffects = {
								blur: 0,
								src: ''
							}
						}

						newList.push(item)
					}

					if (item.isLocal && (item.videoEffects.blur > 0 || item.videoEffects.src !== '')) {
						this.blur = item.videoEffects.blur
						this.background = {
							tooltip: '',
							image: item.videoEffects.src
						}
						// this.saveMediaPipe()
					}
				})

				this.videoList = newList

				this.videoList.forEach((item) => {
					if (!item.isScreenShare && (item.videoEffects.blur > 0 || item.videoEffects.src !== '')) {
						this.startMediaPipe(item)
					}
				})
				if (this.videoList.length > 2) {
					// Pinno il video se è uno screen share ed è l'ultimo ad essere stato aggiunto

					const last = this.videoList[this.videoList.length - 1]
					if (last && last.isScreenShare) {
						this.expand(last)
					}
				}

				this.dimensions()
			}, 1000)
		})
		this.socket.on('newMessage', (message) => {
			const element = document.getElementById('chat-messages')

			this.messages.push({ message: message, private: false })
			if (!this.chatVisible) {
				this.countMessage++
			} else {
				this.countMessage = 0
			}

			const myTimeout = setTimeout(() => {
				element.scrollTop = element.scrollHeight
				this.textMessage = ''
				clearTimeout(myTimeout)
			}, 500)
		})

		this.socket.on('newPrivateMessage', (message) => {
			const element = document.getElementById('chat-messages')

			this.messages.push({ message: message, private: true })
			if (!this.chatVisible) {
				this.countMessage++
			} else {
				this.countMessage = 0
			}

			const myTimeout = setTimeout(() => {
				if (element) {
					element.scrollTop = element.scrollHeight
					this.textMessage = ''
					clearTimeout(myTimeout)
				}
			}, 500)
		})
	},
	unmounted() {
		window.removeEventListener('resize', this.dimensions)
	}
})
</script>
<style lang="scss">
body {
	overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;1,100;1,300;1,400&display=swap');

* {
	font-family: 'Lato', sans-serif;
}

.Container {
	background: #011935;
	background: radial-gradient(circle at 50% 100%, #1e4a7c, #0c2343);
	width: 100%;
	height: 100%;
	-webkit-transform: translateZ(0);
	box-sizing: border-box;
}

@keyframes slidein {
	from {
		width: 0%;
	}

	to {
		width: 100%;
		min-width: 360px;
	}
}

.RightBox {
	height: calc(100% - 15px);
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	flex: 0;
	border-radius: 15px;
	overflow: hidden;
	transition: all 0.4s ease-out;
	overflow: hidden;

	&.open {
		border: 1px solid hsla(210, 65%, 67%, 0.55);
		border-radius: 10px;
		flex: 0 0 30%;
		transition: all 0.4s ease-out 0.1s;
	}

	.chat-container {
		box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		// background: #1a2f49;
		background: #0c1e3d;
		overflow: hidden;
		flex-direction: column;
		height: 100%;
		display: flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		flex: 1;

		.chat-header {
			width: 100%;
			height: 40px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid hsla(210, 65%, 67%, 0.55);
			color: white;
			background: rgba(116, 171, 226, 0.15);

			span,
			svg {
				padding: 0 10px;
				cursor: pointer;
			}

			svg path {
				fill: white;
			}
		}

		.chat-messages {
			width: 100%;
			flex-direction: column;
			display: flex;
			flex: 1;
			overflow-y: auto;

			.chat-messages-info {
				line-height: 1rem;
				margin: 0.75rem;
				padding: 0.75rem;
				text-align: center;
				font-size: 12px;
				border-radius: 8px;
				letter-spacing: 0.025em;
				background: white;
				color: #011730;
				transform: scale(0);
				opacity: 0;

				&.hide {
					transform: scale(0);
					opacity: 0;
				}

				&.show {
					animation: showInfo 0.6s forwards;
					animation-delay: 0.55s;
				}

				&.alreadyOpen {
					transform: scale(1);
					opacity: 1;
				}
			}

			.left-msg .private-badge {
				right: -14px;
				top: -10.5px;
			}

			.right-msg .private-badge {
				left: -12.5px;
				top: -7.5px;
			}

			.private-badge {
				position: absolute;
				border: 1.5px solid #0b1e3d;
				background: #ff004f;
				border-radius: 50%;
				width: 22px;
				height: 22px;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					width: 15px !important;
					height: 15px !important;

					path {
						fill: white;
					}
				}
			}

			.msg {
				display: flex;
				align-items: center;
				margin-bottom: 30px;

				&:first-of-type {
					margin-top: 10px;
				}
			}

			.msg-bubble {
				position: relative;
				display: flex;
				align-items: center;
				position: relative;
				max-width: 70%;
				min-width: 115px;
				border-radius: 15px;
				background: rgb(125, 184, 243);
				color: #011730;
				animation: show 0.4s ease-out;
			}

			.private-msg.right-msg .msg-bubble {
				border-top-left-radius: 0px;
			}

			.private-msg.left-msg .msg-bubble {
				border-top-right-radius: 0px;
			}

			.msg-text {
				word-break: break-word;
				width: 100%;
				padding: 0 10px 5px 20px;
				font-size: 13px;
			}

			.msg.right-msg .msg-info {
				justify-content: flex-end;
				overflow: hidden;
				color: #011730;
			}

			.msg.right-msg .msg-text {
				color: #011730;
			}

			.left-msg .msg-bubble {
				border-bottom-left-radius: 0;
			}

			.left-msg {
				margin-left: 15px;
			}

			.right-msg {
				margin-right: 15px;
				flex-direction: row-reverse;
			}

			.right-msg .msg-bubble {
				background: #bad7ff;
				color: #011730;
				border-bottom-right-radius: 0;
			}

			.right-msg .msg-img {
				margin: 0 0 0 10px;
			}

			.private-msg .msg-bubble {
				color: #011730;
				// background: #ff004f !important;
			}

			.msg-container {
				width: 100%;
				flex-direction: column;
				padding: 2px 10px 5px 10px;
				display: flex;
				align-items: center;

				.msg-info {
					width: 100%;
					padding: 2px 10px 5px 10px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 12px;

					.msg-info-name {
						text-transform: capitalize;
						margin-right: 10px;
						font-weight: bold;
						white-space: nowrap;
						text-overflow: ellipsis;
						width: calc(100% - 40px);
						overflow: hidden;
					}

					.msg-info-time {
						font-size: 0.85em;
					}
				}
			}
		}

		.chat-input {
			background: rgba(116, 171, 226, 0.15);
			position: relative;
			border-top: 1px solid hsla(210, 65%, 67%, 0.55);
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			.suggestion {
				border-radius: 8px;
				position: absolute;
				backdrop-filter: blur(5px);
				left: 12px;
				border: 1px solid hsla(210, 65%, 67%, 0.55);
				animation: show 0.4s ease;

				div:first-child {
					background: #1495de;
					padding: 5px 8px 5px 8px;
					color: white;
				}

				ul {
					background: white;
					min-width: 150px;
					list-style: none;
					margin: 0;
					text-align: left;
					padding: 0;
					max-height: 110px;
					overflow: hidden;
					overflow-y: auto;

					li {
						padding: 5px 8px;
						cursor: pointer;

						&:hover,
						&:focus {
							background: hsla(210, 65%, 67%, 0.55);
							border: 0;
							outline: 0;
						}
					}
				}
			}

			input.chat {
				width: 100%;
				height: 35px !important;
				max-height: 35px !important;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				text-indent: 10px;
				border: 0;
				padding: 0;

				&:focus,
				&:focus-visible {
					outline: 0;
				}
			}

			button.send {
				border: 0;
				padding: 0;
				height: 36px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				background: rgba(0, 174, 255, 1);
				color: white;
				padding: 5px 10px;
				text-transform: uppercase;
				cursor: pointer;

				&:hover {
					background: rgba(0, 174, 255, 0.8);
				}
			}

			.emoji-wrapper {
				animation: show 0.4s ease-out;
				position: absolute;
				bottom: 50px;
				left: 5px;
				width: calc(100% - 10px);
				border-radius: 10px;

				.composer-emoji-popover {
					height: 200px !important;
					width: 100% !important;
					overflow: hidden;
					background: white !important;
					box-shadow: 0 3px 6px rgba(34, 29, 29, 0.56), 0 3px 6px rgba(0, 0, 0, 0.53);

					//background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(204, 209, 215, 1) 100%);
					input,
					input:focus,
					input:focus-visible {
						display: none;
					}

					.emoji-picker-group {
						display: flex;
						flex-wrap: wrap;

						.emoji-picker-emoji {
							margin: 5px 8px;
							font-size: 20px;
							height: 20px;
							width: 20px;
							min-width: 20px;
							max-width: 20px;
							display: flex;
							cursor: pointer !important;
						}
					}

					.composer-popover-body-container {
						display: flex;
						flex-direction: column;
					}

					.composer-popover-body {
						display: flex;
						justify-content: space-between;
						padding: 5px 10px;
						max-height: calc(200px - 48px);
						overflow: hidden;
						overflow-y: auto;
					}

					.emoji-category-group {
						order: -1;
						background: #1b3456;
					}

					.tone-category-group {
						background: #1b3456;
					}

					.tone-category-group,
					.emoji-category-group {
						display: flex;
						padding: 5px 10px;

						.tone-category,
						.category {
							filter: grayscale(70%);
							cursor: pointer;
							height: 25px;
							width: 25px;
							margin: 0px 5.5px;
							min-width: 25px;
							max-width: 25px;
							display: flex;
							justify-content: center;
							align-items: center;
							align-content: center;
						}

						.category:hover {
							filter: grayscale(0%);
							background: #3280d3;
							border-radius: 50%;
						}

						.tone-category:hover {
							filter: grayscale(0%);
						}

						.category span,
						.tone-category span {
							font-size: 20px !important;
						}

						.tone-category.active,
						.category.active {
							filter: grayscale(0%);
							border-bottom: 2px solid #3280d3;
						}
					}

					.tone-category-group+.composer-popover-body {
						max-height: calc(200px - 48px - 30px);
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}

		.chat-tooltip {
			z-index: -1;
			position: absolute;
			background: #0080d3;
			border: 1px solid hsla(210, 65%, 67%, 0.55);
			color: white;
			padding: 5px 15px;
			border-radius: 3px;
			font-size: 12px;
			top: 0px;
			opacity: 0;
			width: max-content;
		}

		.chat-tooltip.emojy {
			left: -300% !important;
		}

		.chat-button-wrapper {
			padding: 0;
			margin: 0 10px 0px 6px;
			width: fit-content;
			position: relative;

			&:first-child {
				margin: 0 6px 0px 10px;
			}

			&:not(.disabled) {
				&:hover {
					.chat-tooltip {
						opacity: 1;
						top: -35px;
						background: #0080d3;
						animation: show 0.7s ease;
						transition: all 0.4s ease;
					}
				}
			}

			.chat-button {
				margin: 0;
				border-radius: 50%;
				width: 28px;
				height: 28px;
				border: 0;
				background: transparent;
				justify-content: center;
				display: flex;
				align-items: center;
				color: white;
				-webkit-backdrop-filter: blur(50px);
				backdrop-filter: blur(50px);
				cursor: pointer;

				svg {
					min-width: 20px;
					min-height: 20px;
				}

				&:hover {
					background: rgba(116, 171, 226, 0.55);
				}
			}

			&.disabled .chat-button {
				opacity: 0.7;
				background: transparent;

				.chat-tooltip {
					opacity: 0;
				}
			}
		}
	}

	.partecipants-container {
		box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		background: #0c1e3d;
		overflow: hidden;
		flex-direction: column;
		height: 100%;
		display: flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		flex: 1;
		border-radius: 10px;

		.partecipants-header {
			background: rgba(116, 171, 226, 0.15);
			width: 100%;
			height: 40px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid hsla(210, 65%, 67%, 0.55);
			color: white;

			span,
			svg {
				cursor: pointer;
				padding: 0 10px;
			}

			svg path {
				fill: white;
			}
		}

		.partecipants-list {
			width: 100%;
			flex-direction: column;
			display: flex;
			flex: 1;
			overflow-y: auto;
			padding: 0.55rem 0.75rem;

			.partecipants-info .badge-container svg {
				width: 25px !important;
				height: auto !important;
			}

			.partecipants-info {
				display: flex;
				padding: 0.25rem 0.75rem;
				align-items: center;
				font-size: 12px;
				border-radius: 8px;
				color: white;
				justify-content: space-between;
				margin: 0 12px 8px 0;
				opacity: 0;
				transform: scale(0);
				transition: all 0.2s ease-out;
				transition-delay: 0s;

				&.show {
					transform: scale(1);
					opacity: 1;
					transition: all 0.4s ease-out;
					transition-delay: 0.2s;
				}

				div.name,
				div.pinned,
				div.audio,
				div.recording {
					min-width: 40px;
					margin: 0;
					text-align: center;
				}

				div {
					&.pinned {
						cursor: pointer;
					}

					&.name {
						padding: 0 10px;
						max-width: 200px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 60%;
						text-align: left;
						font-size: 15px;
					}

					&.recording {
						text-align: right;
					}

					&.audio {
						z-index: 12;

						span {
							display: flex;
							text-align: right;
							justify-content: flex-end;
						}

						span svg {
							margin-left: auto;
							margin-right: 3px;
						}

						.badge {
							margin-left: auto;
							color: white;
						}
					}

					.badge {
						background: #3280d3;
						color: white;
						width: 25px;
						height: 25px;
						display: flex;
						font-size: 15px;
						justify-content: center;
						align-items: center;
						border-radius: 50%;

						[class^='o4_'],
						[class^='o5_'],
						[class^='o6_'] {
							position: relative;
							height: 19%;
							width: 4px;
							background: white;
							margin: 1.5px;
							border-radius: 200px;
							transition: all linear 0.1s;
						}
					}
				}
			}
		}
	}

	.background-container {
		box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		background: #0c1e3d;
		overflow: hidden;
		flex-direction: column;
		height: 100%;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		flex: 1;
		border-radius: 10px;
		color: white;
		min-width: 300px;

		.canvas-container {
			min-width: 260px;
			width: calc(100% - 25px);
			aspect-ratio: 4/3;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			position: relative;
			border-radius: 10px;
			overflow: hidden;
			border: 1px solid hsla(210, 65%, 67%, 1);
			margin-top: 10px;

			.output_canvas_preview_none {
				height: 100%;
				width: 100%;
				background: #0b1e3d;
			}

			canvas {
				object-fit: fill;
				height: 100%;
				width: 100%;
			}
		}

		.scroller-container {
			min-width: 300px;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			padding-bottom: 30px;

			.background-title {
				width: 100%;
				margin: 20px 12px 15px 12px;
			}
		}

		.background-header {
			min-height: 40px;
			background: rgba(116, 171, 226, 0.15);
			width: 100%;
			height: 40px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid hsla(210, 65%, 67%, 0.55);
			color: white;

			span,
			svg {
				cursor: pointer;
				padding: 0 10px;
			}

			svg path {
				fill: white;
			}
		}

		.video-rateos {
			display: flex;
			align-content: center;
			align-items: center;
			width: 100%;

			margin-top: 10px;

			span {
				min-width: max-content;
			}

			.video-rateos-options {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				button.aspect {
					cursor: pointer;
					border-radius: 3px;
					font-size: 0.75rem;
					background: transparent;
					font-weight: 600;
					width: 10vh;
					height: 6vh;
					border-radius: 5px;
					color: white;
					border: 1.5px solid rgba(128, 128, 128, 0.85);

					span {
						margin: 0;
						padding: 0;
						opacity: 0.5;
					}

					&.selected {
						border: 1.5px solid rgba(116, 171, 226, 1);

						span {
							opacity: 1;
						}
					}
				}
			}
		}

		.opt-container {
			margin-top: -20px;
			display: flex;
			flex-direction: column;
		}

		.blur {
			align-items: center;
			justify-content: center;
			display: flex;

			.blur-choice {
				margin: 3px;
				position: relative;
				cursor: pointer;
				display: flex;
				overflow: visible;
				justify-content: center;
				align-items: center;
				border: 1.5px solid rgba(128, 128, 128, 0.85);
				width: 10vh;
				height: 6vh;
				border-radius: 5px;

				svg {
					opacity: 0.55;
				}

				&:nth-child(2) {
					svg {
						opacity: 0.55;
					}
				}

				&:nth-child(3) {
					svg {
						opacity: 0.65;
					}
				}

				&:nth-child(4) {
					svg {
						opacity: 0.75;
					}
				}

				&.selected {
					border: 1.5px solid rgba(116, 171, 226, 1);

					svg {
						opacity: 1;
					}
				}
			}
		}

		.backgrounds-container-items {
			visibility: hidden;
			padding-top: 20px;
			min-width: 300px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&.open {
				visibility: visible;
				transition: all 1s ease 1s;
			}

			.background-choice {
				max-width: calc(25% - 9px);
				margin: 3px;
				position: relative;
				cursor: pointer;
				display: flex;
				overflow: visible;
				justify-content: center;
				align-items: center;
				border: 1.5px solid rgba(128, 128, 128, 0.85);
				width: 10vh;
				height: 6vh;
				border-radius: 5px;
				background-repeat: no-repeat !important;
				background-size: cover !important;

				&.selected {
					border: 2.5px solid #0080d3;
				}
			}
		}

		div.background-footer {
			border-top: 1px solid hsla(210, 65%, 67%, 0.55);
			height: 55px;
			min-height: 55px;
			max-height: 55px;
			width: 100%;
			flex: 1;
			background: rgba(116, 171, 226, 0.15);
			align-items: center;
			display: flex;
			justify-content: flex-end;

			button {
				margin-right: 10px;
				outline: 0;
				border: 0;
				background: #0080d3;
				color: white;
				display: inline-block;
				position: relative;
				cursor: pointer;
				height: 35px;
				line-height: 35px;
				padding: 0 1.5rem;
				color: white;
				font-size: 14px;
				text-align: center;
				vertical-align: middle;
				white-space: nowrap;
				outline: none;
				border: none;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				border-radius: 2px;
				transition: all 0.3s ease-out;
				box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
			}
		}

		.bg-tooltip {
			opacity: 0 !important;
			position: absolute;
			top: 0px;
			left: -7px;
			right: 0;
		}

		.background-choice:hover,
		.blur-choice:hover {
			.bg-tooltip {
				opacity: 1 !important;
				z-index: 1;
				top: -20px;
				font-size: 12px;
				min-width: max-content;
				padding: 3px 6px;
				border-radius: 4px;
				text-align: center;
				color: white;
				background: #0080d3;
				transition: all 0.3 ease;
				animation: show 0.5s ease;
			}
		}
	}
}

.Scenary {
	width: 100%;
	max-width: inherit;
	position: fixed;
	top: 0;
	bottom: 0px;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	//padding: 10px 20px;
	gap: 5px;
	// background-image: radial-gradient(circle at 50% 100%, rgb(1, 92, 153), rgb(2, 36, 69));
	overflow: hidden;
}

.MeetLogo {
	height: 35px;
	padding-left: 20px;
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 20px;
}

.ScreenSharingOn {
	display: flex;
	align-items: center;
	width: 100%;
	transition: all 0.4s ease-out;
	min-height: 40px;
	max-height: 40px;
	position: relative;

	.ScreenSharingOn-container {
		cursor: pointer;
		animation: show 0.4s ease-out;
		position: absolute;
		border-radius: 10px;
		top: 3px;
		left: 0;
		right: 0;
		box-shadow: var(--shadow-dark);
		transition: all 0.4s ease-out;
		border: 1.5px solid hsla(210, 65%, 67%, 0.55);
		background: #0c1e3d;
		color: white;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: space-between;
		height: 35px;
		width: calc(100% - 3px);

		span {
			display: flex;
			align-items: center;
			align-content: center;
			padding: 0 10px 0 20px;
		}

		button {
			background: #3280d3;
			color: white;
			border: 1px solid hsla(210, 65%, 67%, 0.55);
			padding: 3px 10px;
			border-radius: 5px;
		}
	}
}

.PinnedContainer {
	overflow: hidden;
	position: relative;
	width: calc(100% - 3px);
	height: calc(100% - 3px);
	background: #1a2f49;
	border: 1.5px solid hsla(210, 65%, 67%, 0.55);
	border-radius: 15px;
	display: flex;
	justify-content: center;
}

.ScreenSharingOn+.PinnedContainer {
	height: calc(100% - 60px);
}

/* Container of Screen and Dish */
.Recording {
	display: flex;
	border-radius: 10px;
	height: 0px;
	max-width: 100%;
	align-items: flex-end;

	&.--on {
		height: 50px;
		animation: show 0.4s ease;

		span.alert-text {
			opacity: 1;
			transition: all 1s ease;
		}
	}

	.recording-alert-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		//background: hsla(210, 65%, 67%, 0.59);
		background: #0c1e3d;
		// border-bottom: 1px solid hsla(210, 65%, 67%, 0.55);
		// border-top: 1px solid hsla(210, 65%, 67%, 0.55);
		width: 100%;
		height: 35px;
		overflow: hidden;
		color: rgb(223, 84, 84);

		.record-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgba(255, 82, 82, 0.2);
			width: 35px;
			height: 35px;
			border-radius: 50%;
			margin-right: 10px;
			margin-left: 0px;

			svg {
				border-radius: 50%;
				animation: pulse-red 2s infinite;
				fill: #f14c49;
			}
		}

		span.alert-tooltip {
			display: none;
			position: absolute;
		}
	}

	&.--on.--on-right {
		.recording-alert-wrapper {
			background: transparent;
			transition: all 1.5s ease;
			position: relative;
			border: 0;

			.record-icon {
				margin-left: 94.2%;
				transition: all 1.8s ease;
				cursor: pointer;
			}

			.record-icon:hover+.alert-text+.alert-tooltip {
				display: block;
				opacity: 1;
				position: absolute;
				right: 75px;
				background: #0080d3;
				border: 1px solid hsla(210, 65%, 67%, 0.55);
				color: white;
				padding: 5px 15px;
				border-radius: 3px;
				animation: show 0.5s ease;
				font-size: 12px;
			}

			span.alert-text {
				width: 0;
				opacity: 0;
				white-space: nowrap;
				transition: opacity 1.3 ease, width 2.5s ease;
				transition-delay: 0s, 2s;
			}

			span.alert-tooltip {
				display: block;
				opacity: 0;
			}
		}
	}
}

.Conference {
	display: flex;
	flex: 1;
	border-radius: 10px;
	max-height: calc(100% - 110px);
	max-width: 100%;
	padding: 10px 20px;
	transition: height 0.4s ease-out;
}

.Recording.--on+.Conference {
	max-height: calc(100% - 160px);
	transition: height 0.4s ease-out;
}

/* Container of Cameras */
.Dish {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	flex: 1;
	border-radius: 15px;
	overflow: hidden;
	transition: all 0.4s ease-out;
}

/* Camera */
.Dish>div {
	border: 1px solid hsla(210, 65%, 67%, 0.55);
	position: relative;
	vertical-align: middle;
	align-self: center;
	border-radius: 10px;
	overflow: hidden;
	display: inline-block;
	box-shadow: var(--shadow-dark);
	transition: all 0.4s ease-out;
	animation: show 0.5s ease;
}

.Dish>div canvas {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	left: 0;
	top: 0;
}

/* Video (check the nice property object-fit) */
.Dish>div video {
	transform: rotateY(180deg) !important;
	-webkit-transform: rotateY(180deg) !important;
	-moz-transform: rotateY(180deg) !important;
	transition: rotate 0ms ease;
	position: absolute;
	right: 0;
	object-fit: cover;
	bottom: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	left: 0;
	top: 0;
	background-size: cover;
	overflow: hidden;
	transition: rotate 0ms ease;

	&.isScreenShare {
		transition: rotate 0ms ease;
		transform: rotateY(0deg) !important;
		-webkit-transform: rotateY(0deg) !important;
		-moz-transform: rotateY(0deg) !important;
	}
}

/* Aspect Ratio Number */
.Dish div.video-container:after {
	color: #aaa;
	font-size: 13px;
	font-family: 'Lato', sans-serif;
	position: absolute;
	bottom: 20px;
	left: 23px;
	font-weight: 100;
	content: attr(data-aspect);
	display: block;
}

/* Gray Diagonal */
.Dish div.video-container:before {
	position: absolute;
	height: 100%;
	// background: url(./../general/diagonal.jpg);
	background-size: 100% 100%;
	width: 100%;
	opacity: 0.3;
	font-weight: 100;
	content: '';
	display: block;
}

/* Screen */
.Screen {
	overflow: hidden;
	flex: 0;
	position: relative;
	display: none;
	transition: all 0.4s ease-out;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	margin: 5px 15px;
	transition: all 0.4s ease-out;

	&.pinned {
		justify-content: space-between;
		flex: 2;
		transition: all 0.4s ease-out;
		display: flex;
		flex-direction: column;
		transition: all 0.4s ease-out;
		animation: show 0.4s ease-out;

		video {
			transform: rotateY(180deg) !important;
			-webkit-transform: rotateY(180deg) !important;
			-moz-transform: rotateY(180deg) !important;
			position: absolute;
			right: 1px;
			object-fit: cover;
			bottom: 1px;
			height: 100%;
			width: 100%;
			margin: 0 auto;
			border-radius: 10px;
			overflow: hidden;
			left: 1px;
			top: 1px;
			background-size: cover;
			overflow: hidden;
			transition: rotate 0ms ease;

			&.isScreenShare {
				transition: rotate 0ms ease;
				transform: rotateY(0deg) !important;
				-webkit-transform: rotateY(0deg) !important;
				-moz-transform: rotateY(0deg) !important;
			}
		}

		.info {
			bottom: 30px;
			left: 2%;
		}
	}
}

/* Animation of Cameras */
@keyframes opacityAl {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes showAlertRecord {
	0% {
		opacity: 0;
		transform: scale(0.4) translateY(20px);
	}

	10% {
		opacity: 1;
		transform: scale(1) translateY(0);
	}

	100% {
		width: 40px;
	}
}

@keyframes show {
	0% {
		opacity: 0;
		transform: scale(0.4) translateY(20px);
	}

	100% {
		opacity: 1;
		transform: scale(1) translateY(0);
	}
}

@keyframes showInfo {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.pin {
	position: absolute;
	opacity: 0;
	z-index: -1;
	backdrop-filter: blur(5px);
	background-color: hsla(210, 65%, 67%, 0.35);
	// border: 1px solid hsla(210, 65%, 67%, 1);
	position: absolute;
	top: calc(50% - 15px);
	left: calc(50% - 15px);
	border-radius: 50%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5 ease-in;
}

.Screen,
.Dish>div.video-container {
	&:hover {
		user-select: none;

		.pin {
			z-index: 5;
			opacity: 0.8;
			transition: all 0.5 ease-in;

			svg {
				height: 12px;
				width: 12px;
			}

			&:hover {
				backdrop-filter: blur(30px);
				background-color: transparent;
				border: 1px solid hsla(210, 65%, 67%, 0);

				opacity: 1;
				z-index: 10;
				transition: all 0.5 ease-in;
				cursor: pointer;

				svg path {
					fill: #02c9fa !important;
				}
			}
		}
	}

	.recording {
		z-index: 1;
		position: absolute;
		top: 10px;
		right: 5px;
		width: 35px;
		height: 35px;
		color: white;
		backdrop-filter: blur(10px);
		background: #ffffff0b;
		backdrop-filter: blur(10px);
		border: 1px solid #ff3b38;
		border-radius: 50%;
		font-weight: 600;
		text-align: center;
		border: 0px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 3vh;
			height: 3vh;
			border-radius: 50%;
			animation: pulse-red 2s infinite;

			path {
				fill: #f14c49;
			}
		}
	}

	.audio {
		z-index: 12;
		position: absolute;
		bottom: 10px;
		right: 10px;
		width: 35px;
		height: 35px;
		color: white;
		//border: 1px solid #ff004f;
		backdrop-filter: blur(10px);
		background: #ff00513e;
		border-radius: 50%;
		font-weight: 600;
		text-align: center;

		&.active {
			background: rgba(0, 174, 255, 0.5);
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			position: absolute;
			width: 3vh;
			height: 3vh;

			path {
				fill: white;
			}
		}
	}

	.container-initials {
		text-transform: uppercase;
		width: 100%;
		height: 100%;
		//background: rgba(116, 171, 226, 0.15);
		background: #01193520;
		display: flex;
		align-content: center;
		justify-content: center;
		position: relative;
		transition: all 0.5s ease;

		[class^='initials-audio-effect-'] {
			background: hsla(190, 100%, 55%, 0.105);
			width: 14.9vh;
			height: 14.9vh;
			z-index: 1;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			border-radius: 50%;
			text-align: center;
			opacity: 0;
			transition: all 650ms ease-out;
			animation: display 1s ease-out;
		}

		[class^='initials-audio-effect-big-'] {
			background: transparent;
			opacity: 0;
			border: 1px solid hsla(210, 65%, 67%, 1);
			width: 14.9vh;
			height: 14.9vh;
			z-index: 2;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			border-radius: 50%;
			text-align: center;
			transition: all 800ms ease-out;
			animation: display 1s ease-out;
		}

		.initials {
			z-index: 3;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			width: 15vh;
			height: 15vh;
			color: white;
			border-radius: 50%;
			line-height: 15vh;
			font-size: 7vh;
			font-weight: 600;
			text-align: center;
			animation: show 0.5s ease-out;
		}
	}

	.info {
		z-index: 10;
		padding: 0 10px;
		height: 35px;
		line-height: 31px !important;
		border-radius: 5px;
		margin: 10px;
		//border: 1px solid rgba(116, 171, 226, 0.5);
		backdrop-filter: blur(10px);
		//background: #01193520;
		color: #fff;
		color: rgba(116, 171, 226, 1);
		position: absolute;
		text-transform: capitalize;
		bottom: 0;
		font-size: 1.05rem;
		font-weight: 500;
		line-height: 1.25rem;
		letter-spacing: 0.2px;
		overflow: hidden;
		text-overflow: ellipsis;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.6);
		white-space: nowrap;
	}
}

.Controls {
	display: flex;
	align-items: center;
	position: absolute;
	width: 100%;
	background: #01193520;
	bottom: 0;
	height: 100px;
	max-height: 100px;

	.Controls-meet-title {
		padding: 0 0 0 25px;
		display: flex;
		flex-direction: column;
		flex: 0 0 20%;
		color: white;
		font-size: 14px;

		p {
			margin: 0;
			padding-left: 35px;
		}
	}

	.Controls-info {
		padding: 0 25px 0 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 0 0 20%;
		height: 100%;

		.chat-action-mobile {
			display: none;
		}

		.chat-action {
			cursor: pointer;
			position: relative;
			margin-left: 10px;

			.count-badge {
				border: 1px solid #01c9fa;
				display: inline-block;
				position: absolute;
				left: -5px;
				font-weight: 600;
				top: 45px;
				border-radius: 3rem;
				text-align: center;
				font-size: 0.8rem;
				padding: 0.05rem 0.4rem 0.15rem;
				line-height: inherit;
				background: #0080d3;
				color: white;
				box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
				transform: scale(0.8);

				&.list-users-count {
					&.active {
						animation: pulse-white 2s infinite;
					}
				}

				&.message-chat-count {
					animation: pulse-white 2s infinite;
				}
			}

			button:not(.aspect) {
				cursor: pointer;
				color: white;
				background: transparent;
				border: 0;
				box-shadow: unset;
				margin: 0 6px;
				width: 100%;
				height: 22px;
				backdrop-filter: unset;

				svg {
					width: 20px;
					height: 20px;

					path {
						fill: #fffd;
					}
				}

				&:hover {
					box-shadow: unset;
				}

				&.active {
					svg path {
						fill: #01c9fa;
					}
				}
			}
		}

		.chat-action {
			display: flex;
			cursor: pointer;
			width: 47px;
			height: 100%;
			height: 100%;
			position: relative;
			overflow: visible;
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				.media-tooltip {
					opacity: 1;
					top: -10px;
					background: #0080d3;
					animation: show 0.7s ease;
					transition: all 0.4s ease;
				}
			}

			.media-tooltip {
				position: absolute;
				background: #0080d3;
				border: 1px solid hsla(210, 65%, 67%, 0.55);
				color: white;
				padding: 5px 15px;
				border-radius: 3px;
				font-size: 12px;
				top: 10px;
				opacity: 0;
				width: max-content;
			}

			.media-tooltip:before {
				left: 50% !important;
				transform: translate(-50%, -50%) !important;
			}

			.media-effects {
				margin-right: 15px !important;

				svg {
					width: 22px !important;
					height: 22px !important;
				}

				&.mediapipeOn {
					svg path {
						fill: #01c9fa !important;
					}
				}
			}
		}
	}

	.Controls-main-buttons {
		display: flex;
		justify-content: center;
		flex: 1;

		.media-action-button-wrapper:first-of-type {
			margin-left: -16px;
		}

		.media-action-button-wrapper:last-of-type {
			margin-left: 16px;
		}

		.media-action-button-wrapper {
			cursor: pointer;
			width: 56px;
			height: 100%;
			position: relative;
			overflow: visible;
			display: flex;
			justify-content: center;

			.timestamp {
				position: absolute;
				bottom: -15px;
				color: white;
				opacity: 0.5;
				font-size: 10px;
				animation: show 1s ease;
			}

			&:hover {
				.media-tooltip {
					opacity: 1;
					top: -40px;
					background: #0080d3;
					animation: show 0.7s ease;
					transition: all 0.4s ease;
				}
			}
		}

		.media-tooltip {
			position: absolute;
			background: #0080d3;
			border: 1px solid hsla(210, 65%, 67%, 0.55);
			color: white;
			padding: 5px 15px;
			border-radius: 3px;
			font-size: 12px;
			top: 0;
			opacity: 0;
			top: -10px;
			width: max-content;

			&:before {
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		button {
			border-radius: 50%;
			width: 40px;
			height: 40px;
			border: 0;
			background: transparent;
			justify-content: center;
			display: flex;
			align-items: center;
			color: white;
			backdrop-filter: blur(50px);
			cursor: pointer;

			&:hover {
				opacity: 0.85;
				cursor: pointer;
				background: hsla(210, 65%, 67%, 0.55) !important;
			}

			svg {
				width: 26px;
				height: 26px;

				path {
					fill: #01c9fa;
				}
			}

			&.record {
				background-color: transparent;

				svg {
					animation: pulse-red 2s infinite;
					border-radius: 50%;

					path {
						fill: #d33232;
					}
				}
			}

			&.hangup {
				background-color: #ff004f !important;
				border-radius: 100px;
				width: 56px;
				height: 40px;
				overflow: hidden;
				padding: 8px 12px;

				svg path {
					fill: white;
				}

				&:hover {
					background-color: #fb0a56 !important;
					cursor: pointer;
					opacity: 0.85;
				}
			}

			&.media-action-button-disabled {
				opacity: 0.5;
				// background-color: #ff7019;
				background: transparent;

				svg path {
					fill: white;
				}
			}

			&.bg-transparent {
				// background-color: hsla(210, 65%, 67%, 0.25);
				background: transparent;
				color: white;
			}
		}
	}
}

@keyframes display {
	0% {
		visibility: hidden;
	}

	99% {
		visibility: hidden;
	}

	100% {
		visibility: visible;
	}
}

@keyframes showAvatar {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

@keyframes pulse-green {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px rgba(51, 217, 178, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
	}
}

.Controls .Group {
	display: flex;
	align-items: center;
	gap: 20px;
}

.Controls .Options {
	display: flex;
	gap: 10px;
}

.Controls .Options div {
	display: inline-block;
	font-size: 12px;
}

.Controls .Link {
	color: #000;
	text-decoration: none;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 100;
	letter-spacing: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: auto;
}

.Controls .Link i {
	margin-left: 10px;
	font-size: 30px;
}

@keyframes pulse-white {
	0% {
		transform: scale(0.75);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(0.8);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.75);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.Screen.pinned+.Dish>div.video-container .initials {
	width: 13vh !important;
	height: 13vh !important;
	line-height: 13vh !important;
	font-size: 6vh !important;
}

.Screen.pinned+.Dish .container-initials {
	[class^='initials-audio-effect-'] {
		width: 12.9vh !important;
		height: 12.9vh !important;
	}
}

@media only screen and (max-width: 950px) {
	.Screen.pinned+.Dish>div.video-container .initials {
		width: 11vh !important;
		height: 11vh !important;
		line-height: 11vh !important;
		font-size: 5vh !important;
	}

	.Screen.pinned+.Dish .container-initials {
		[class^='initials-audio-effect-'] {
			width: 10.9vh !important;
			height: 10.9vh !important;
		}
	}
}

@media only screen and (max-width: 680px) {
	.Conference.boxRightOpen div.column {
		flex: 0;
		width: 0;
	}

	.Screen.pinned {
		order: 2;
		display: flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		flex: 1;
		border-radius: 10px;

		video {
			object-fit: cover;
			width: 100%;
		}

		.info {
			width: 100%;
			bottom: 30px;
			left: -10px;
		}
	}

	.Screen.pinned+.Dish {
		display: none;
		min-height: 50px !important;
		max-height: 60px;
		min-width: 50px !important;

		.video-container,
		video {
			min-height: 50px !important;
			max-height: 60px;
			min-width: 50px !important;
		}

		.info {
			opacity: 0;
		}

		.audio {
			top: 1px;
			right: 1px;
			width: 3.5vh;
			height: 3.5vh;
			z-index: 10;
		}
	}

	.Controls {
		.Controls-meet-title {
			display: none;
		}

		.Controls-main-buttons {
			padding-left: 10%;
		}

		.Controls-info {
			flex: 0 0 5%;

			.chat-action {
				display: none;
			}

			.chat-action-mobile {
				cursor: pointer;
				display: flex;

				.menu-list {
					background: white;
					position: absolute;
					z-index: -1;
					min-height: 100px;
					min-width: 220px;
					border-radius: 5px;
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					display: flex;
					opacity: 0;
					overflow: hidden;
					padding: 5px 0;

					&.show {
						opacity: 1;
						z-index: 10;
						top: -100px;
						right: 25px;
						transition: all 0.4s ease-in-out forward;
						animation: show 0.4s ease;

						&+svg.settings {
							transform: rotate(180deg);
							transition: transform 0.4s;
						}
					}

					div.menu-list-option {
						height: 38px;
						padding: 5px 15px;
						cursor: pointer;
						display: flex;
						align-items: center;
						transition: background 0.2s ease;

						&.disabled {
							opacity: 0.5;
						}

						&:hover {
							background: #dbdada5c;
							transition: background 0.2s ease;
						}

						svg {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}

	.RightBox.open {
		flex: 1 !important;
		min-width: 100% !important;
	}
}

.effect-container {
	right: 5px;
	bottom: 10px;
	background: #02c9fa;
	margin: 0.5rem;
	width: 28px;
	height: 28px;
	position: absolute;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;

	[class^='o1_'],
	[class^='o2_'],
	[class^='o3_'] {
		position: relative;
		height: 19%;
		width: 5px;
		background: white;
		margin: 1.5px;
		border-radius: 200px;
		transition: all linear 0.1s;
	}
}

.Toast {
	position: absolute;
	margin: auto;
	flex: 1;
	color: #ff4f4d;
	display: flex;
	justify-content: center;
	left: 0;
	right: 0;
	top: -10px;
	z-index: -1;
	height: 40px;

	* {
		visibility: hidden;
	}

	&.show {
		+.Scenary {
			top: 40px;
			transition: all 0.4 ease-in;
		}

		top: 10px;
		z-index: 10;
		opacity: 1;
		transition: all 0.4s ease-in-out;

		* {
			visibility: visible;
			transition: all 0.4s ease-in-out;
		}

		.toast-container {
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #ff4f4d;
			border-radius: 5px;
			min-width: 250px;
			min-height: 30px;
			padding: 5px 8px;

			svg {
				margin-right: 12px;
				border-radius: 50%;
				animation: pulse-red 2s infinite;
			}
		}
	}
}</style>
